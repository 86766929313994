import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { RootState } from '@store/index';
import LocationTable from '../../components/LocationsTable';
import LayoutHeader from '../../components/LayoutHeader';
import LocationCard from '@components/LocationCard';
import { getLocations } from '@store/Actions/GetLocactions';
import './Locations.css';
import CreateLocation from '@components/CreateLocation';

interface IProps {
    screenWidth: number;
    getLocations: (companyId: number) => void;
    userAuth: {
        companyId: number;
    };
}

const Locations: React.FC<IProps> = ({
    screenWidth,
    getLocations,
    userAuth,
}) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const handleModal = (e) => {
        e.preventDefault();
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div className="user__container">
            <div className="user__header">
                <LayoutHeader title="Locaciones" />
                <Space>
                    <Button
                        type="default"
                        icon={<ReloadOutlined />}
                        style={{ width: 50 }}
                        onClick={() => getLocations(userAuth.companyId)}
                    />

                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={(e) => {
                            handleModal(e);
                        }}
                        style={screenWidth <= 512 ? { width: 50 } : {}}
                    >
                        {screenWidth > 512 && 'Agregar Locación'}
                    </Button>
                </Space>
            </div>
            <CreateLocation
                modalVisible={modalVisible}
                closeModal={closeModal}
            />
            {screenWidth > 635 ? <LocationTable /> : <LocationCard />}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        screenWidth: state.ScreenResize.screenWidth,
        userAuth: state.IsAuth.userAuth,
    };
};

const mapDispatchToProps = {
    getLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
