import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { Form, Input, Modal, Dropdown, Menu, Button, DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { updateTask } from '../../core/store/Actions/UpdateTask';
import { getTasks } from '../../core/store/Actions/GetTasks';
import { RootState } from '@store/index';
import { ITask } from '@components/TaskTable/types';

interface Props {
    modalVisible: boolean;
    rowData: {
        taskConfId: number;
        description: string;
        taskConfName: string;
        duration: number;
        isCompleted: number;
        isOnTime: number;
        isNew: number;
        createdDate: number;
        finishedDate: number;
        tasks: [
            {
                userName: string;
                userLastName: string;
                taskConfId: number;
                userId: number;
                createdDate: number;
                finishedDate: number;
                isCompleted: number;
                isOnTime: number;
                isNew: number;
                taskId: number;
                franchiseId: number;
                franchiseName: string;
                taskConfName: string;
                description: string;
                duration: number;
                fieldId: number;
                inputTypeId: number;
                inputName: string;
                isRequired: number;
                min: number;
                max: number;
                fieldName: string;
                value: string | null;
            },
        ];
        nameComplete: string;
        status: string;
        key: number;
    };
    closeModal: () => void;
    updateTask: (
        taskConfId: number,
        name: string,
        description: string,
        duration: number,
        userId: number,
        finishedDate: number,
        isCompleted: number,
        isOnTime: number,
        isNew: number,
        values: [
            {
                userName: string;
                userLastName: string;
                taskConfId: number;
                userId: number;
                createdDate: number;
                finishedDate: number;
                isCompleted: number;
                isOnTime: number;
                isNew: number;
                taskId: number;
                franchiseId: number;
                franchiseName: string;
                taskConfName: string;
                description: string;
                duration: number;
                fieldId: number;
                inputTypeId: number;
                inputName: string;
                isRequired: number;
                min: number;
                max: number;
                fieldName: string;
                value: string | null;
            },
        ],
    ) => void;
    loading: boolean;
    getTasks: () => void;
    userAuth: {
        userId: number;
    };
}

const UpdateTask: React.FC<Props> = ({
    modalVisible,
    rowData,
    closeModal,
    updateTask,
    loading,
    getTasks,
    userAuth,
}): JSX.Element => {
    const [status, setStatus] = useState<string>('');
    const [taskOnTime, setTaskOnTime] = useState<any>(undefined);
    const [taskCompleted, setTaskCompleted] = useState<any>(undefined);
    const [time, setTime] = useState<number>(0);
    const [form] = Form.useForm();

    useEffect(() => {
        if (rowData?.isOnTime == 1 && rowData.isCompleted == 1) {
            setStatus('Completado');
        }
        if (rowData?.isOnTime == 1 && rowData.isCompleted == 0) {
            setStatus('En progreso');
        }
        if (rowData?.isOnTime == 0 && rowData.isCompleted == 1) {
            setStatus('No completado');
        }

        form.setFieldsValue({
            name: rowData?.taskConfName,
            description: rowData?.description,
        });
    }, [rowData]);

    const range = (start, end) => {
        const result: any = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    const disabledDate = (current: Moment) => {
        // Can not select days before today
        return current && current < moment().startOf('day');
    };

    const disabledTime = (current) => {
        if (current && current.date() > new Date().getDate()) {
            return {};
        }
        return {
            disabledHours: () => range(0, current?.hour() || 0),
            disabledMinutes: () => range(0, current?.minute() || 0),
        };
    };

    const overlay = () => (
        <Menu onClick={(status) => handleStatus(status.key)}>
            <Menu.Item key="0">En progreso</Menu.Item>
            <Menu.Item key="1">Completado</Menu.Item>
            <Menu.Item key="2">No Completado</Menu.Item>
        </Menu>
    );

    const handleStatus = (status) => {
        if (status === '0') {
            setTaskOnTime(1);
            setTaskCompleted(0);
            setStatus('En progreso');
        }

        if (status === '1') {
            setTaskOnTime(1);
            setTaskCompleted(1);
            setStatus('Completado');
        }

        if (status === '2') {
            setTaskOnTime(0);
            setTaskCompleted(1);
            setStatus('No completado');
        }
    };

    const onChangeTime = (date) => {
        setTime(date.valueOf());
    };

    const isCompletedTask = (): number => {
        let epoch = new Date().valueOf();

        return Math.floor(epoch / 1000);
    };

    const onOk = async () => {
        const values = await form.validateFields();
        const deadLineSeconds = Math.floor(time / 1000) || time;

        await updateTask(
            rowData?.taskConfId,
            values.name,
            values.description,
            status === 'En progreso' ? deadLineSeconds : rowData?.duration,
            userAuth.userId,
            status === 'Completado' ? isCompletedTask() : rowData?.finishedDate,
            taskCompleted !== undefined ? taskCompleted : rowData?.isCompleted,
            taskOnTime !== undefined ? taskOnTime : rowData?.isOnTime,
            rowData?.isNew,
            rowData?.tasks,
        );

        form.resetFields();
        closeModal();
        getTasks();
    };

    return (
        <Modal
            visible={modalVisible}
            title={`Editando: ${rowData?.taskConfName}`}
            onCancel={() => closeModal()}
            cancelText="Cancelar"
            okText="Editar"
            confirmLoading={loading}
            onOk={() => onOk()}
        >
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
            >
                <Form.Item name="name" label="Nombre">
                    <Input type="text" placeholder={rowData?.taskConfName} />
                </Form.Item>
                <Form.Item name="description" label="Descripción">
                    <Input type="text" placeholder={rowData?.description} />
                </Form.Item>

                <Form.Item name="status" label="Estado">
                    <Dropdown overlay={() => overlay()} trigger={['click']}>
                        <Button onClick={(e) => e.preventDefault()}>
                            {status}
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </Form.Item>
                {status === 'En progreso' && (
                    <Form.Item
                        name="time"
                        label="Fecha de entrega"
                        rules={[
                            {
                                required:
                                    status === 'En progreso' ? true : false,
                                message: 'Por favor, seleccione las sucursales',
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Fecha final"
                            showTime
                            onChange={onChangeTime}
                            disabledDate={disabledDate}
                            disabledTime={disabledTime}
                            format="YYYY/MM/DD HH:mm:ss"
                            style={{ width: '100%' }}
                            showSecond={false}
                        />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.UpdateTask.loading,
        userAuth: state.IsAuth.userAuth,
    };
};

const mapDispatchToProps = {
    updateTask,
    getTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTask);
