import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import Axios from '../../../axios/axios';

export const getIncidents = () => async (dispatch: AppDispatch) => {
    dispatch({
        type: 'LOADING_INCIDENTS',
        payload: true,
    });

    try {
        const response = await Axios.get(`/incidences`);
        if (response.data.message !== 'There are not incidences to show') {
            response.data = response.data;
        } else {
            response.data = [];
        }
        dispatch({
            type: 'GET_INCIDENTS',
            payload: response.data,
        });

        dispatch({
            type: 'GET_INCIDENTS_CARD',
            payload: response.data,
        });
    } catch (error) {
        notification.error({
            message: 'Ocurrió un error.',
            description: '¡No se pudieron cargar las incidencias!',
        });
    } finally {
        dispatch({
            type: 'LOADING_INCIDENTS',
            payload: false,
        });
    }
};
