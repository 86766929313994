import React from 'react';
import { Result } from 'antd';
import Dashboard from '@components/Dashboard';

interface IHome {}

const Home: React.FC<IHome> = ({}) => {
    return (
        <div
        // style={{
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     height: '100%',
        // }}
        >
            <Dashboard />
            {/* <Result
                status="404"
                title="En construccion"
                subTitle="!Estamos trabajando para traer esta página pronto!"
                // extra={<Button type="primary">Back Home</Button>}
            /> */}
        </div>
    );
};

export default Home;
