import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import Axios from '../../../axios/axios';

export const getLocations =
    (companyId: number) => async (dispatch: AppDispatch) => {
        dispatch({
            type: 'LOADING_LOCATIONS',
            payload: true,
        });
        try {
            let response = await Axios.get(
                `/franchises?companyId=${companyId}`,
            );

            if (response.data.message !== 'There are not places to show') {
                response.data = response.data;
            } else {
                response.data = [];
            }

            dispatch({
                type: 'GET_LOCATIONS',
                payload: response.data,
            });
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Ocurrió un error.',
                description: '¡No se pudieron cargar las sucursales!',
            });
        } finally {
            dispatch({
                type: 'LOADING_LOCATIONS',
                payload: false,
            });
        }
    };
