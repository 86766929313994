import React from 'react';
import './Layout.css';
import { Breadcrumb } from 'antd';

interface Props {
    title: string;
    subTitle?: string;
}

const Layout: React.FC<Props> = ({ title, subTitle }): JSX.Element => {
    return (
        <Breadcrumb className="hero">
            <Breadcrumb.Item className="hero__breadcrumb">
                {title}
            </Breadcrumb.Item>
            {subTitle && (
                <Breadcrumb.Item className="hero__breadcrumb">
                    {subTitle}
                </Breadcrumb.Item>
            )}
        </Breadcrumb>
    );
};

export default Layout;
