import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFound from '../pages/NotFound';

import routes from './routes';
import routes2 from './routes2';

interface NavigationProps {
    isAuthenticated: any;
}

const Navigation: React.FC<NavigationProps> = ({ isAuthenticated }) => {
    return (
        <Router>
            <Switch>
                {isAuthenticated === true
                    ? routes.map((route) => (
                          <Route
                              key={route.path}
                              path={route.path}
                              exact={route.exact}
                              render={(props) => (
                                  <route.layout>
                                      <route.component {...props} />
                                  </route.layout>
                              )}
                          />
                      ))
                    : isAuthenticated === false &&
                      routes2.map((route) => (
                          <Route
                              key={route.path}
                              path={route.path}
                              exact={route.exact}
                              render={(props) => (
                                  <route.layout>
                                      <route.component {...props} />
                                  </route.layout>
                              )}
                          />
                      ))}
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};

export default Navigation;
