import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { RootState } from '@store/index';
import { getTasks } from '@store/Actions/GetTasks';
import CreateTask from '../CreateTask';
import TaskTable from '../../components/TaskTable';
import LayoutHeader from '../../components/LayoutHeader';
import './Tasks.css';
import TaskCard from '@components/TaskCard';

interface IProps {
    getTasks: () => void;
    screenWidth: number;
}

const Tasks: React.FC<IProps> = ({ getTasks, screenWidth }): JSX.Element => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const handleModal = (e) => {
        e.preventDefault();
        setModalVisible(true);
    };

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div className="tasks__container">
            <div className="tasks__header">
                <LayoutHeader title="Tareas" />
                <Space>
                    <Button
                        type="default"
                        // shape="round"
                        style={{ width: 50 }}
                        icon={<ReloadOutlined />}
                        onClick={() => {
                            getTasks();
                        }}
                    />
                    <Button
                        type="primary"
                        // shape="circle"
                        icon={<PlusOutlined />}
                        onClick={(e) => {
                            handleModal(e);
                        }}
                        style={screenWidth <= 512 ? { width: 50 } : {}}
                    >
                        {screenWidth > 512 && 'Agregar Tarea'}
                    </Button>
                </Space>
            </div>
            <CreateTask modalVisible={modalVisible} closeModal={toggleModal} />
            {screenWidth > 635 ? <TaskTable /> : <TaskCard />}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        screenWidth: state.ScreenResize.screenWidth,
    };
};

const mapDispatchToProps = {
    getTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
