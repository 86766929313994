import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Space, Table, Image } from 'antd';
import { connect } from 'react-redux';
import { RootState } from '@store/index';
import { getIncidents } from '@store/Actions/GetIncidents';
import { updateIncidents } from '@store/Actions/UpdateIncidents';
import { DownOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import ImageView from '@components/ImageView';

interface IProps {
    getIncidents: () => void;
    users: [];
    incidents: [];
    loading: boolean;
    updateIncidents: (incidentId: number, newValue: string) => void;
}

interface Names {
    text: string;
}

const IncidencesTable: React.FC<IProps> = ({
    getIncidents,
    updateIncidents,
    incidents,
    loading,
}): JSX.Element => {
    const dataSource: any[] = [];
    const [names, setNames] = useState<Names[]>([]);
    const [url, setUrl] = useState<string[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const setModal = () => {
        setModalVisible(!modalVisible);
    };

    useEffect(() => {
        // getAllUsers();
        getIncidents();
    }, []);

    useEffect(() => {
        let names: string[] = [];
        dataSource.map((element) => {
            names.push(element.name);
        });
        filterUniqueNames(names);
    }, [incidents]);

    const filterUniqueNames = (name: string[]) => {
        let uniqueNames: any[] = [];
        let unique = name.filter((c, index) => {
            return name.indexOf(c) === index;
        });

        unique.forEach((element) => {
            uniqueNames.push({ text: element, value: element });
        });
        setNames(uniqueNames);
    };

    incidents.forEach((incidence: any) => {
        dataSource.push({
            incidentId: incidence.incidenceId,
            name: `${incidence.name} ${incidence.lastName}`,
            franchiseName: incidence.franchiseName,
            title: incidence.title,
            description: incidence.description,
            photos: incidence.photos,
            status:
                incidence.status === 'Success' ? 'Terminado' : 'En Progreso',
        });
    });

    const overlay = (status, incidence) => (
        <Menu onClick={(status) => handleOverlayClick(status, incidence)}>
            <Menu.Item key="Success">Terminado</Menu.Item>
            <Menu.Item key="In Progress">En progreso</Menu.Item>
        </Menu>
    );

    const handleOverlayClick = async (status, incidence) => {
        await updateIncidents(incidence.incidentId, status.key);

        getIncidents();
    };

    const handlePhotoData = (photos) => {
        setUrl(photos);
        setModalVisible(!modalVisible);
    };

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            filters: names,
            width: '20%',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            filterSearch: true,
        },
        {
            title: 'Titulo',
            dataIndex: 'title',
            width: '20%',
            sorter: (a, b) => a.title.length - b.title.length,
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            width: '30%',
            sorter: (a, b) => a.description.length - b.description.length,
        },
        {
            title: 'Fotos',
            dataIndex: 'photos',
            key: 'photos',
            width: '20%',
            render: (photos) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {photos.length !== 0 ? (
                        <>
                            <Image src={photos[0]} width={25} height={25} />

                            <Space size="large" align="center">
                                <a
                                    onClick={() => handlePhotoData(photos)}
                                    style={{ marginLeft: '1em' }}
                                >
                                    Ver ({photos.length})
                                </a>
                            </Space>
                        </>
                    ) : (
                        <span>Sin foto</span>
                    )}
                </div>
            ),
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            width: '15%',
            render: (status, incidence) => (
                <Space>
                    <Dropdown
                        overlay={() => overlay(status, incidence)}
                        // trigger={['click']}
                    >
                        <Button>
                            {status} <DownOutlined />
                        </Button>
                    </Dropdown>
                </Space>
            ),
            filters: [
                {
                    text: 'Terminado',
                    value: 'Terminado',
                },
                {
                    text: 'En Progreso',
                    value: 'En Progreso',
                },
            ],
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            filterSearch: true,
        },
    ];

    return (
        <>
            <Table
                size="small"
                loading={loading}
                dataSource={dataSource}
                // @ts-ignore
                columns={columns}
            />
            <ImageView
                modalVisible={modalVisible}
                setModalVisible={setModal}
                url={url}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        users: state.GetUsers.users,
        incidents: state.GetIncidents.incidents,
        loading: state.GetIncidents.loading,
    };
};

const mapDispatchToProps = {
    getIncidents,
    updateIncidents,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidencesTable);
