import React, { useState } from 'react';
import { Typography, Input, Button, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Axios from '../../axios/axios';

interface Props {}

const RecoverPassword: React.FC<Props> = ({}): JSX.Element => {
    const history = useHistory();
    const { Text, Title } = Typography;
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const recovery = async () => {
        setLoading(true);
        try {
            await Axios.post('/forgotpass', {
                email: email,
            });
            notification.success({
                message: 'Codigo enviado.',
                description:
                    '¡El codigo de verificación se ha enviado a su correo!',
            });
            history.push('/recover-code');
        } catch (error) {
            notification.error({
                message: 'Codigo fallido.',
                description:
                    '¡No se ha podido enviar el codigo de verificación!',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            recovery();
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh',
                padding: '1em',
            }}
        >
            <Title level={2}>Recuperación de contraseña.</Title>
            <Text>
                Por favor ingrese su correo electronico para enviarle un codigo
                de recuperación.
            </Text>
            <Input
                style={{
                    width: '300px',
                    marginTop: '1em',
                }}
                type="email"
                placeholder="Ingrese aquí su correo electronico."
                prefix={<UserOutlined />}
                onChange={(e) => setEmail(e.target.value)}
                allowClear
                onKeyPress={(e) => handleKeyPress(e)}
            />
            <Button
                type="primary"
                style={{ marginTop: '1em' }}
                onClick={() => recovery()}
                loading={loading}
            >
                Enviar codigo
            </Button>
        </div>
    );
};

export default RecoverPassword;
