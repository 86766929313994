// import UpdateIncidents from '../../Apis/UpdateIncidents';
import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import Axios from '../../../axios/axios';

export const updateIncidents =
    (incidentId: number, newValue: string) => async (dispatch: AppDispatch) => {
        dispatch({
            type: 'LOADING_INCIDENTS',
            payload: true,
        });

        try {
            await Axios.put('/incidences', {
                id: incidentId,
                columnName: 'Status',
                newValue: newValue,
            });
            dispatch({
                type: 'LOADING_INCIDENTS',
                payload: false,
            });
            notification.success({
                message: 'Incidencia actualizada',
                description: '¡La incidencia fue actualizada exitosamente!',
            });
        } catch (error) {
            dispatch({
                type: 'LOADING_INCIDENTS',
                payload: false,
            });
            notification.error({
                message: 'Ocurrió un error.',
                description: '¡No se pudo actualizar la incidencia!',
            });
            console.log(error, 'error update incidents');
        }
    };
