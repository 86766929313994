import React, { useEffect } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { RootState } from '../../core/store';
import { getFranchise } from '../../core/store/Actions/GetFranchise';

interface IProps {
    getFranchise: (companyId: number) => void;
    allFranchises: [];
    setFranchise: (value: any) => void;
    franchise: number[];
    userAuth: {
        companyId: number;
    };
}

interface IFranchise {
    comapanyId: number;
    phoneNumbe: string;
    lat: number;
    lng: number;
    name: string;
    franchiseId: number;
}

const FranchiseUser: React.FC<IProps> = ({
    getFranchise,
    allFranchises,
    setFranchise,
    franchise,
    userAuth,
}): JSX.Element => {
    const { Option } = Select;

    useEffect(() => {
        getFranchise(userAuth.companyId);
    }, [franchise]);

    const handleChange = (value) => {
        setFranchise([value]);
    };

    const options = allFranchises.map((franchise: IFranchise) => (
        <Option
            value={franchise.franchiseId}
            key={franchise.franchiseId}
            label={franchise.name}
        >
            {franchise.name}
        </Option>
    ));

    return (
        <div>
            <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                placeholder="Seleccionar sucursal"
                allowClear
                showSearch
                optionFilterProp="label"
                onChange={(value) => handleChange(value)}
            >
                {options}
            </Select>
        </div>
    );
};

const mapDispatchToProps = {
    getFranchise,
};

const mapStateToProps = (state: RootState) => ({
    allFranchises: state.GetFranchise.franchise,
    userAuth: state.IsAuth.userAuth,
});

export default connect(mapStateToProps, mapDispatchToProps)(FranchiseUser);
