import React from 'react';
import { List as AntList, Avatar, Typography, Space } from 'antd';

type Props = {
    data: any[];
    title: string;
    colorTitle: string;
    colorName: string;
};

const List: React.FC<Props> = ({ data, title, colorTitle, colorName }) => {
    const { Title } = Typography;
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '1em',
                }}
            >
                <Title level={5} style={{ color: colorTitle }}>
                    {title}
                </Title>
            </div>
            <AntList
                itemLayout="horizontal"
                dataSource={data}
                size="small"
                split={false}
                style={{ paddingBottom: '1em' }}
                renderItem={(item) => (
                    <AntList.Item>
                        <AntList.Item.Meta
                            avatar={
                                <Avatar src="https://joeschmoe.io/api/v1/random" />
                            }
                            title={
                                <a
                                    href="https://ant.design"
                                    style={{ color: colorName }}
                                >
                                    {item.title}
                                </a>
                            }
                            description="Ant Design, a design language"
                        />
                    </AntList.Item>
                )}
            />
        </div>
    );
};

export default List;
