import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import Axios from '../../../axios/axios';

export const updateTask =
    (
        taskConfId: number,
        name: string,
        description: string,
        duration: number,
        userId: number,
        finishedDate: number,
        isCompleted: number,
        isOnTime: number,
        isNew: number,
        tasks: [
            {
                userName: string;
                userLastName: string;
                taskConfId: number;
                userId: number;
                createdDate: number;
                finishedDate: number;
                isCompleted: number;
                isOnTime: number;
                isNew: number;
                taskId: number;
                franchiseId: number;
                franchiseName: string;
                taskConfName: string;
                description: string;
                duration: number;
                fieldId: number;
                inputTypeId: number;
                inputName: string;
                isRequired: number;
                min: number;
                max: number;
                fieldName: string;
                value: string | null;
            },
        ],
    ) =>
    async (dispatch: AppDispatch) => {
        let values: any = [];
        tasks.forEach((task) => {
            if (task.value == null) {
                values = null;
            } else {
                values = [
                    {
                        taskId: task.taskId,
                        value: task.value,
                        inputType: task.inputName,
                    },
                ];
            }
        });

        dispatch({
            type: 'LOADING_UPDATE_TASK',
            payload: true,
        });

        try {
            await Axios.put('/tasks', {
                taskConfId,
                name,
                description,
                duration,
                userId,
                finishedDate,
                isCompleted,
                isOnTime,
                isNew,
                values, //{taskId: el.taskId, value: Array<string>(), inputType: el.inputName}
            });

            notification.success({
                message: 'Tarea actualizada',
                description: '¡La tarea fue actualizada exitosamente!',
            });
            dispatch({
                type: 'LOADING_UPDATE_TASK',
                payload: false,
            });
        } catch (error) {
            const err = error as AxiosError;
            console.log(err.response?.data, 'eror');

            notification.error({
                message: 'Ocurrio un error',
                description: '¡No se pudo actualizar la tarea!',
            });
            dispatch({
                type: 'LOADING_UPDATE_TASK',
                payload: false,
            });
        }
    };
