import { AnyAction } from 'redux';

interface Incidents {
    incidents: string[];
    incidencesCard: [];
    loading: boolean;
    loadingStatus: boolean;
}

const initialState: Incidents = {
    incidents: [],
    incidencesCard: [],
    loading: false,
    loadingStatus: false,
};

export const GetIncidents = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'GET_INCIDENTS':
            return {
                ...state,
                incidents:
                    action.payload !== 'There are not incidences to show'
                        ? action.payload
                        : [],
            };
        case 'GET_INCIDENTS_CARD': {
            const incidenceData: any = [];

            action.payload.forEach((element: any) => {
                incidenceData.push({
                    ...element,
                    name: `${element.name} ${element.lastName}`,

                    status:
                        element.status === 'Success'
                            ? 'Terminado'
                            : 'En Progreso',
                });
            });

            return {
                ...state,
                incidencesCard: incidenceData,
            };
        }
        case 'LOADING_INCIDENTS':
            return {
                ...state,
                loading: action.payload,
            };
        case 'LOADING_STATUS':
            return {
                ...state,
                loadingStatus: action.payload,
            };
        default:
            return state;
    }
};
