import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Space } from 'antd';
import {
    Autocomplete,
    GoogleMap,
    LoadScript,
    Marker,
} from '@react-google-maps/api';
import { connect } from 'react-redux';
import { updateLocation } from '@store/Actions/UpdateLocation';
import { getLocations } from '@store/Actions/GetLocactions';
import { RootState } from '@store/index';
import './index.css';

interface IProps {
    modalVisible: boolean;
    closeModal: () => void;
    rowData: {
        comapanyId: number;
        franchiseId: number;
        lat: number;
        lng: number;
        name: string;
        phoneNumber: string;
        address: string;
        isActive: number;
    };
    getLocations: (companyId: number) => void;
    updateLocation: (
        phone: string,
        lat: number,
        lng: number,
        name: string,
        franchiseId: number,
        address: string,
        isActive: number,
    ) => void;
    loading: boolean;
    screenWidth: number;
    userAuth: {
        companyId: number;
    };
}

interface IPosition {
    lat: number;
    lng: number;
}

const UpdateLocation: React.FC<IProps> = ({
    modalVisible,
    closeModal,
    rowData,
    updateLocation,
    loading,
    screenWidth,
    getLocations,
    userAuth,
}): JSX.Element => {
    const { TextArea } = Input;
    const [form] = Form.useForm();

    const [autoComplete, setAutoComplete] = useState<any>(null);
    const [position, setPosition] = useState<IPosition>({
        lat: 0,
        lng: 0,
    });

    useEffect(() => {
        form.setFieldsValue({
            name: rowData.name,
            phone: rowData.phoneNumber,
            address: rowData.address,
        });
        setPosition({
            lat: rowData.lat,
            lng: rowData.lng,
        });
    }, [rowData]);

    const onLoad = (autocomplete) => {
        setAutoComplete(autocomplete);
    };

    const onOk = async () => {
        const values = await form.validateFields();

        await updateLocation(
            values.phone,
            position.lat,
            position.lng,
            values.name,
            rowData.franchiseId,
            values.address,
            rowData.isActive,
        );

        closeModal();
        getLocations(userAuth.companyId);
    };

    const onPlaceChanged = () => {
        if (autoComplete !== null) {
            form.setFieldsValue({
                name: autoComplete.gm_accessors_.place.Xi.place.name,
                phone: autoComplete.gm_accessors_.place.Xi.place.international_phone_number?.replace(
                    / /g,
                    '',
                ),
                address:
                    autoComplete.gm_accessors_.place.Xi.place.formatted_address,
            });

            if (
                autoComplete.gm_accessors_.place.Xi.place.geometry !== undefined
            ) {
                setPosition({
                    lat: autoComplete.gm_accessors_.place.Xi.place.geometry.location.lat(),
                    lng: autoComplete.gm_accessors_.place.Xi.place.geometry.location.lng(),
                });
            } else {
                setPosition({
                    lat: 0,
                    lng: 0,
                });
            }
        }
    };

    return (
        <Modal
            title="Editando locacion"
            visible={modalVisible}
            onCancel={() => closeModal()}
            onOk={() => onOk()}
            width={screenWidth <= 800 ? '100%' : '80%'}
            confirmLoading={loading}
            destroyOnClose={true}
        >
            <div className="container__updateLocation">
                <div className="box__modal__updateLocation">
                    <LoadScript
                        // @ts-ignore
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        libraries={['places']}
                    >
                        <GoogleMap
                            mapContainerStyle={{
                                width: '100%',
                                height: '100%',
                            }}
                            center={{ lat: position.lat, lng: position.lng }}
                            zoom={18}
                            options={{
                                streetViewControl: false,
                                fullscreenControl: false,
                                mapTypeControl: false,
                                zoomControl: false,
                            }}
                        >
                            <Autocomplete
                                onLoad={onLoad}
                                onPlaceChanged={onPlaceChanged}
                            >
                                <Input
                                    onPressEnter={() => {}}
                                    style={{
                                        boxSizing: `border-box`,
                                        border: `1px solid transparent`,
                                        width: `240px`,
                                        height: `32px`,
                                        padding: `0 12px`,
                                        borderRadius: `3px`,
                                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                        fontSize: `14px`,
                                        outline: `none`,
                                        textOverflow: `ellipses`,
                                        position: 'absolute',
                                        left: '50%',
                                        marginLeft: '-120px',
                                        marginTop: '10px',
                                    }}
                                />
                            </Autocomplete>
                            <Marker position={position} />
                        </GoogleMap>
                    </LoadScript>
                </div>
                <Space
                    direction="vertical"
                    style={
                        screenWidth <= 800
                            ? { marginTop: '1.5em' }
                            : { padding: '0 1em' }
                    }
                >
                    <Form form={form} layout="vertical">
                        <Form.Item
                            name="name"
                            label="Nombre"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, ingrese un nombre',
                                },
                            ]}
                        >
                            <Input placeholder="Nombre de tu locación" />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label="Telefono"
                            rules={[
                                {
                                    required: true,
                                    pattern:
                                        /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                    message: 'Por favor, ingrese un telefono',
                                },
                            ]}
                        >
                            <Input placeholder="Numero de tu locación" />
                        </Form.Item>
                        <Form.Item
                            name="address"
                            label="Direccion"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, ingrese una direccion',
                                },
                            ]}
                        >
                            <TextArea
                                placeholder="Dirección de tu locación"
                                rows={4}
                            />
                        </Form.Item>
                    </Form>
                </Space>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.UpdateLocation.loading,
        screenWidth: state.ScreenResize.screenWidth,
        userAuth: state.IsAuth.userAuth,
    };
};

const mapDispatchToProps = {
    updateLocation,
    getLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLocation);
