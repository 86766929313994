import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { RootState } from '@store/index';
// import { getAllUsers } from '@store/Actions/GetUsers';
import { getIncidents } from '@store/Actions/GetIncidents';
import IncidencesTable from '@components/IncidencesTable';
import Header from '@components/LayoutHeader';
import './styles.css';
import IncidencesCard from '@components/IncidencesCard';

interface IIncidents {
    // getAllUsers: () => void;
    getIncidents: () => void;
    incidents: [];
    loading: boolean;
    screenWidth: number;
}

const Incidents: React.FC<IIncidents> = ({
    getIncidents,
    screenWidth,
}): JSX.Element => {
    return (
        <div style={{ padding: '2em' }}>
            <div className="incidences__header">
                <Header title="Incidencias" />
                <Button
                    // shape="circle"
                    style={{ width: 50 }}
                    type="default"
                    icon={<ReloadOutlined />}
                    onClick={() => getIncidents()}
                />
            </div>
            {screenWidth > 635 ? <IncidencesTable /> : <IncidencesCard />}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        users: state.GetUsers.users,
        incidents: state.GetIncidents.incidents,
        loading: state.GetIncidents.loading,
        screenWidth: state.ScreenResize.screenWidth,
    };
};

const mapDispatchToProps = {
    // getAllUsers,
    getIncidents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Incidents);
