import { AnyAction } from 'redux';

interface UserLogged {
    userLogged: string[];
    loading: boolean;
    isAuthenticated: boolean;
}

const initialState: UserLogged = {
    userLogged: [],
    loading: false,
    isAuthenticated: false,
};

export const Login = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'USER_LOGGED':
            return {
                ...state,
                userLogged: action.payload,
            };
        case 'LOADING':
            return {
                ...state,
                loading: action.payload,
            };
        case 'IS_AUTHENTICATED':
            return {
                ...state,
                isAuthenticated: action.payload,
            };

        default:
            return state;
    }
};
