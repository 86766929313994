import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import Axios from '../../../axios/axios';

export const deleteLocation =
    (
        phone: string,
        lat: number,
        lng: number,
        name: string,
        franchiseId: number,
        address: string,
        isActive: number,
    ) =>
    async (dispatch: AppDispatch) => {
        try {
            await Axios.put('/franchises', {
                phone,
                lat,
                lng,
                name,
                franchiseId,
                isActive,
                address,
            });

            notification.success({
                message: 'Locación eliminada',
                description: '¡La locación fue eliminada correctamente!',
            });
        } catch (error) {
            notification.error({
                message: 'Ocurrio un error.',
                description: '¡No se pudo eliminar la locación!',
            });
        }
    };
