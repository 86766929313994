import { notification } from 'antd';
import { AxiosError } from 'axios';
import Axios from '../../../axios/axios';

export const createUser =
    (
        email: string,
        name: string,
        lastName: string,
        workPosition: string,
        phone: string,
        franchiseId: number[],
        password: string,
        // photo: string,
        companyId: number,
        startShiftTime: string,
        endShiftTime: string,
    ) =>
    async (dispatch) => {
        dispatch({
            type: 'LOADING',
            payload: true,
        });

        try {
            await Axios.post('/users', {
                email,
                name,
                lastName,
                startShiftTime,
                endShiftTime,
                photo: 'uri',
                passwd: password,
                phone,
                franchiseId,
                workPosition,
                companyId,
            });

            dispatch({
                type: 'LOADING',
                payload: false,
            });

            notification.success({
                message: 'Usuario creado.',
                description: '¡El usuario ha sido creado exitosamente!',
            });
        } catch (error) {
            const err = error as AxiosError;
            console.log(error, 'new user');
            dispatch({
                type: 'LOADING',
                payload: false,
            });

            if (err.response?.status === 400) {
                notification.error({
                    message: 'Usuario fallido.',
                    description: '¡El telofono o el email ya existen!',
                });
            } else {
                notification.error({
                    message: 'Usuario fallido.',
                    description:
                        '¡No se ha podido crear un usuario nuevo, por favor intente de nuevo!',
                });
            }
        }
    };
