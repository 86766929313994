import React, { useState } from 'react';
import { Modal, Form, Input, Button, TimePicker } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import FranchiseUser from '@components/FranchiseUser';
import { getAllUsers } from '@store/Actions/GetUsers';
import { connect } from 'react-redux';
import { RootState } from '../../core/store';
import { createUser } from '../../core/store/Actions/CreateUser';
import { getFranchise } from '../../core/store/Actions/GetFranchise';

interface ICreateTask {
    modalVisible: boolean;
    setModalVisible: () => void;
    createUser: (
        email: string,
        name: string,
        lastName: string,
        workPosition: string,
        phone: string,
        franchiseId: number[],
        password: string,
        userAuth: number,
        startShiftTime: string,
        endShiftTime: string,
    ) => void;
    loading: boolean;
    getAllUsers: (franchiseId: number) => void;
    userAuth: {
        companyId: number;
        franchiseId: number;
    };
}

const CreateUser: React.FC<ICreateTask> = ({
    modalVisible,
    setModalVisible,
    createUser,
    loading,
    getAllUsers,
    userAuth,
}): JSX.Element => {
    const [franchiseId, setFranchiseId] = useState<number[]>([]);
    const [startShiftTime, setStartShiftTime] = useState<string>('00:00:00');
    const [endShiftTime, setEndShiftTime] = useState<string>('00:00:00');

    const [requireFranchise, setRequireFranchise] = useState<boolean>(true);

    const setFranchise = (value) => {
        setFranchiseId(value);
    };

    const onChange = (time, timeString) => {
        setStartShiftTime(timeString[0]);
        setEndShiftTime(timeString[1]);
    };

    const onOk = async () => {
        franchiseId.length > 0
            ? setRequireFranchise(false)
            : setRequireFranchise(true);
        const values = await form.validateFields();

        await createUser(
            values.email,
            values.name,
            values.lastName,
            'employee', //workPosition,
            values.phone,
            franchiseId,
            values.password,
            userAuth.companyId,
            startShiftTime,
            endShiftTime,
        );
        form.resetFields();
        setModalVisible();
        getAllUsers(userAuth.franchiseId);
    };

    const [form] = Form.useForm();

    return (
        <Modal
            visible={modalVisible}
            title="Nuevo usuario"
            onCancel={setModalVisible}
            onOk={() => onOk()}
            footer={[
                <Button key="back" onClick={setModalVisible}>
                    Cancelar
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={onOk}
                >
                    Crear
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
            >
                <Form.Item
                    name="name"
                    label="Nombre"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, ingrese un nombre',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    name="lastName"
                    label="Apellido"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, ingrese un apellido',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Telefono"
                    rules={[
                        {
                            required: true,
                            pattern:
                                /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/,
                            message: 'Por favor, ingrese un telefono',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Correo"
                    rules={[
                        {
                            required: true,
                            pattern:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Por favor, ingrese un correo electronico',
                        },
                    ]}
                >
                    <Input type="email" />
                </Form.Item>
                <Form.Item
                    name="shift"
                    label="Turno"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, ingrese un horario.',
                        },
                    ]}
                >
                    <TimePicker.RangePicker
                        style={{ width: '100%' }}
                        onChange={onChange}
                        // placeholder={[
                        //     rowData.startShiftTime,
                        //     rowData.endShiftTime,
                        // ]}
                    />
                </Form.Item>

                <Form.Item
                    name="franchiseId"
                    label="Locacion"
                    rules={[
                        {
                            required: requireFranchise,
                            message: 'Por favor, ingrese una locacion',
                        },
                    ]}
                >
                    <FranchiseUser
                        setFranchise={setFranchise}
                        franchise={franchiseId}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Contraseña"
                    rules={[
                        {
                            required: true,
                            min: 8,
                            message: 'Por favor, ingrese una contraseña',
                        },
                    ]}
                >
                    <Input.Password
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    allFranchises: state.GetFranchise.franchise,
    loading: state.CreateUser.loading,
    userAuth: state.IsAuth.userAuth,
});

const mapDispatchToProps = {
    createUser,
    getFranchise,
    getAllUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
