import { notification } from 'antd';
import { AppDispatch } from '..';
import { AxiosError } from 'axios';

import Axios from '../../../axios/axios';

export const createNewTask =
    (
        taskName: string,
        description: string,
        duration: number,
        isTaskGroup: boolean,
        usersId: number[],
        inputsFields: [
            {
                inputFieldId: number;
                isRequired: boolean;
                min: number;
                max: number;
                name: string;
            },
        ],
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch({
            type: 'LOADING',
            payload: true,
        });

        try {
            await Axios.post('/tasks', {
                taskName,
                description,
                duration,
                isTaskGroup,
                usersId,
                inputsFields,
            });
            dispatch({
                type: 'LOADING',
                payload: false,
            });

            notification.success({
                message: 'Tarea creada.',
                description: '¡La tarea ha sido creada exitosamente!',
            });
        } catch (error) {
            const err = error as AxiosError;
            console.log(err.response?.data);

            dispatch({
                type: 'LOADING',
                payload: false,
            });
            notification.error({
                message: 'Tarea fallida.',
                description: '¡No se ha podido crear la tarea!',
            });
        }
    };
