import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import Axios from '../../../axios/axios';

export const updateLocation =
    (
        phone: string,
        lat: number,
        lng: number,
        name: string,
        franchiseId: number,
        address: string,
        isActive: number,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch({
            type: 'LOADING_UPDATE_LOCATION',
            payload: true,
        });

        console.log(
            {
                phone,
                lat,
                lng,
                name,
                franchiseId,
                isActive,
                address,
            },
            'updat locaction',
        );

        try {
            await Axios.put('/franchises', {
                phone,
                lat,
                lng,
                name,
                franchiseId,
                isActive,
                address,
            });
            notification.success({
                message: 'Locacion actualizada',
                description: '¡La locacion fue actualizada correctamente!',
            });
        } catch (error) {
            notification.error({
                message: 'Ocurrio un error.',
                description: '¡No se pudo actualizar la locacion!',
            });
            console.log(error, 'UPDATE USER ERROR');
        } finally {
            dispatch({
                type: 'LOADING_UPDATE_LOCATION',
                payload: false,
            });
        }
    };
