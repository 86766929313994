import React from 'react';
import { Typography, Input, Form, Space, Button, notification } from 'antd';
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    LockOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { updatePassword } from '@store/Actions/UpdatePassword';
import { RootState } from '@store/index';

interface Props {
    updatePassword: (password: string, userId: number) => void;
    loading: boolean;
    emailCode: {
        Code: number;
        UserId: number;
    };
}

const UpdatePassword: React.FC<Props> = ({
    updatePassword,
    loading,
    emailCode,
}): JSX.Element => {
    const { Title, Text } = Typography;
    const history = useHistory();

    const [form] = Form.useForm();

    const onFinish = async (value) => {
        await form.validateFields();
        if (value.password === value.repeatPassword) {
            await updatePassword(value.password, emailCode.UserId);
            history.push('/');
        } else {
            notification.error({
                message: 'Contraseña fallida.',
                description: '¡Las contraseñas no son iguales!',
            });
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh',
                padding: '0 1em',
            }}
        >
            <Title level={2}>Cambie de contraseña.</Title>
            <Text>
                Por favor, escriba una nueva contraseña para esta cuenta.
            </Text>
            <div
                style={{
                    marginTop: '1em',
                    width: '300px',
                }}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={(value) => onFinish(value)}
                >
                    <Form.Item
                        label="Contraseña"
                        name="password"
                        rules={[
                            {
                                min: 8,
                                required: true,
                                message:
                                    'Por favor, ingrese una contraseña de 8 caracteres minimo',
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder="Contraseña"
                            prefix={<LockOutlined />}
                            iconRender={(visible) =>
                                visible ? (
                                    <EyeTwoTone />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Repetir contraseña"
                        name="repeatPassword"
                        rules={[
                            {
                                min: 8,
                                required: true,
                                message:
                                    'Por favor, repita la contraseña de 8 caracteres',
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder="Repetir contraseña"
                            prefix={<LockOutlined />}
                            iconRender={(visible) =>
                                visible ? (
                                    <EyeTwoTone />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )
                            }
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Continuar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.RecoverPassword.loading,
        emailCode: state.RecoverPassword.emailCode,
    };
};

const mapDispatchToProps = {
    updatePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
