import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { getAllUsers } from '@store/Actions/GetUsers';
import { connect } from 'react-redux';
import CreateUser from '../CreateUser';
import UserTable from '../../components/UserTable';
import LayoutHeader from '../../components/LayoutHeader';
import './User.css';
import UserCard from '@components/UserCard/UserCard';
import { RootState } from '@store/index';

interface IProps {
    getAllUsers: (franchiseId: number) => void;
    userAuth: {
        companyId: number;
        franchiseId: number;
    };
    screenWidth: number;
}

const Users: React.FC<IProps> = ({
    getAllUsers,
    userAuth,
    screenWidth,
}): JSX.Element => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const handleModal = (e) => {
        e.preventDefault();
        setModalVisible(true);
    };

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div className="user__container">
            <div className="user__header">
                <LayoutHeader title="Usuarios" subTitle="Colaborador" />
                <Space>
                    <Button
                        type="default"
                        style={{ width: 50 }}
                        icon={<ReloadOutlined />}
                        onClick={() => getAllUsers(userAuth.franchiseId)}
                    />

                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={(e) => {
                            handleModal(e);
                        }}
                        style={screenWidth <= 512 ? { width: 50 } : {}}
                    >
                        {screenWidth > 512 && 'Agregar Colaborador'}
                    </Button>
                </Space>
            </div>
            <CreateUser
                modalVisible={modalVisible}
                setModalVisible={toggleModal}
            />
            {screenWidth > 635 ? <UserTable /> : <UserCard />}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        userAuth: state.IsAuth.userAuth,
        screenWidth: state.ScreenResize.screenWidth,
    };
};

const mapDispatchToProps = {
    getAllUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
