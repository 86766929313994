import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { RootState } from '@store/index';
import { IFranchise } from '@pages/CreateTask/Franchise';
import { getFranchise } from '@store/Actions/GetFranchise';
import { getManagers } from '@store/Actions/GetManagers';
import { createManager } from '@store/Actions/CreateManager';

interface Props {
    modalVisible: boolean;
    closeModal: () => void;
    allFranchises: IFranchise[];
    getFranchise: (companyId: number) => void;
    createManager: (
        email: string,
        name: string,
        lastName: string,
        workPosition: string,
        phone: string,
        franchiseId: number[],
        password: string,
        companyId: number,
    ) => void;
    loading: boolean;
    getManagers: () => void;
}

const CreateManager: React.FC<Props> = ({
    modalVisible,
    closeModal,
    allFranchises,
    getFranchise,
    createManager,
    loading,
    getManagers,
}) => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const companyId = parseInt(Cookies.get('companyId') ?? '');

    const [franchise, setFranchise] = useState<number[]>([]);

    useEffect(() => {
        getFranchise(companyId);
    }, []);

    const onOk = async () => {
        const values = await form.validateFields();

        await createManager(
            values.email,
            values.name,
            values.lastName,
            'manager',
            values.phone,
            values.franchise,
            values.password,
            companyId ?? '',
        );

        form.resetFields();
        closeModal();
        getManagers();
    };

    const handleChange = (value) => {
        setFranchise(value);
    };

    const options = allFranchises.map((franchise: IFranchise) => (
        <Option
            value={franchise.franchiseId}
            key={franchise.franchiseId}
            label={franchise.name}
        >
            {franchise.name}
        </Option>
    ));

    return (
        <Modal
            visible={modalVisible}
            onCancel={() => closeModal()}
            onOk={() => onOk()}
            okText="Crear"
            cancelText="Cancelar"
            confirmLoading={loading}
            title="Nuevo Supervisor"
        >
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
            >
                <Form.Item
                    label="Nombres"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, ingrese un nombre',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    label="Apellidos"
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, ingrese un apellido',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            pattern:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Por favor, ingrese un correo',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    label="Telefono"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            pattern:
                                /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/,

                            message: 'Por favor, ingrese un telefono',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                        {
                            min: 8,
                            required: true,
                            message:
                                'Por favor, ingrese una constraseña de 8 caracteres minimo',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    name="franchise"
                    label="Locacion"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, ingrese una locación',
                        },
                    ]}
                >
                    <Select
                        placeholder="Seleccionar locación"
                        allowClear
                        showSearch
                        mode="multiple"
                        optionFilterProp="label"
                        onChange={(value) => handleChange(value)}
                    >
                        {options}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        allFranchises: state.GetFranchise.franchise,
        loading: state.CreateManager.loading,
    };
};

const mapDispatchToProps = {
    getFranchise,
    createManager,
    getManagers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateManager);
