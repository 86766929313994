import { AppDispatch } from '@store/index';
// import GetFranchise from '../../Apis/GetFranchise';
import Axios from '../../../axios/axios';

export const getFranchise =
    (companyId: number) => async (dispatch: AppDispatch) => {
        try {
            let response = await Axios.get(
                `/franchises?companyId=${companyId}`,
            );

            if (response.data.message !== 'There are not places to show') {
                response.data = response.data;
            } else {
                response.data = [];
            }

            dispatch({
                type: 'GET_FRANCHISE',
                payload: response.data,
            });
        } catch (error) {
            console.log(error);
        }
    };
