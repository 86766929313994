// Basic REDUX store according to https://react-redux.js.org/introduction/basic-tutorial
import { createStore, combineReducers, Store, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { GetUsers } from './reducers/GetUsers';
import { CreateUser } from './reducers/CreateUser';
import { GetFranchise } from './reducers/GetFranchise';
import { GetIncidents } from './reducers/GetIncidents';
import { Login } from './reducers/Login';
import { IsAuth } from './reducers/IsAuth';
import { CreateTask } from './reducers/CreateTask';
import { UpdateUser } from './reducers/UpdateUser';
import { UpdateTask } from './reducers/UpdateTask';
import { GetTasks } from './reducers/GetTasks';
import { ScreenResize } from './reducers/ScreenResize';
import { ActiveUsers } from './reducers/ActiveUsers';
import { GetLocations } from './reducers/GetLocations';
import { CreateLocation } from './reducers/CreateLocation';
import { UpdateLocation } from './reducers/UpdateLocation';
import { RecoverPassword } from './reducers/RecoverPassword';
import { GetManagers } from './reducers/GetManagers';
import { GetDirectors } from './reducers/GetDirectors';
import { CreateManager } from './reducers/CreateManager';
import { UpdateManager } from './reducers/UpdateManager';
import { UpdateDirector } from './reducers/UpdateDirector';
import { CreateDirector } from './reducers/CreateDirector';

const rootReducer = combineReducers({
    CreateUser,
    GetUsers,
    GetFranchise,
    GetIncidents,
    Login,
    IsAuth,
    CreateTask,
    UpdateUser,
    UpdateTask,
    GetTasks,
    ScreenResize,
    ActiveUsers,
    GetLocations,
    CreateLocation,
    UpdateLocation,
    RecoverPassword,
    GetManagers,
    GetDirectors,
    CreateManager,
    UpdateManager,
    UpdateDirector,
    CreateDirector,
});

const store: Store = createStore(
    rootReducer,
    {},
    composeWithDevTools(applyMiddleware(thunk)),
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
