import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import Axios from '../../../axios/axios';

export const getAllUsers = () => async (dispatch: AppDispatch) => {
    dispatch({
        type: 'LOADING_USERS',
        payload: true,
    });

    try {
        let response = await Axios.get(
            `users?listingType=company&userType=employee`,
        );

        if (response.data.message !== 'There are not users to show') {
            response.data = response.data;
        } else {
            response.data = [];
        }

        const activeUsers = response.data?.filter(
            (user) => user.isActive === 1,
        );

        dispatch({
            type: 'GET_ACTIVE_USERS',
            payload: activeUsers,
        });

        dispatch({
            type: 'GET_USERS',
            payload: response.data.reverse(),
        });
        dispatch({
            type: 'LOADING_USERS',
            payload: false,
        });
    } catch (error) {
        dispatch({
            type: 'LOADING_USERS',
            payload: false,
        });
        notification.error({
            message: 'Ocurrió un error.',
            description: '¡No se pudieron cargar los usuarios!',
        });
    }
};
