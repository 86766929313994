import React, { useState, useEffect } from 'react';
import { RootState } from '@store/index';
import { connect } from 'react-redux';
import { Card, Dropdown, Menu, Modal, Skeleton } from 'antd';
import { getLocations } from '@store/Actions/GetLocactions';
import CardFilter from '@components/CardFilter';
import { MoreOutlined } from '@ant-design/icons';
import LocationDetailCard from '@components/LocationDetailCard';
import UpdateLocation from '@components/UpdateLocation';
import Cookies from 'js-cookie';

interface IProps {
    location: any[];
    loading: boolean;
    getLocations: (companyId: number) => void;
    userAuth: {
        companyId: number;
    };
}

export interface ILocation {
    comapanyId: number;
    franchiseId: number;
    lat: number;
    lng: number;
    name: string;
    phoneNumber: string;
    address: string;
    isActive: number;
}

const LocationCard: React.FC<IProps> = ({
    location,
    loading,
    getLocations,
    userAuth,
}): JSX.Element => {
    const companyId = parseInt(Cookies.get('companyId') ?? '');

    const [query, setQuery] = useState<string>('');
    const [data, setData] = useState<ILocation[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [rowData, setRowData] = useState<ILocation>({
        comapanyId: 0,
        franchiseId: 0,
        lat: 0,
        lng: 0,
        name: '',
        phoneNumber: '',
        address: '',
        isActive: 0,
    });

    useEffect(() => {
        getLocations(companyId);
    }, []);

    const searchFilter = (search) => {
        setQuery(search);

        const searchResult = location.filter(
            (query: ILocation) =>
                query.name.toLowerCase().includes(search.toLowerCase()) ||
                query.phoneNumber
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                query.address.toLowerCase().includes(search.toLowerCase()),
        );

        if (search.length > 0) {
            setData(searchResult);
        } else {
            setData(location);
        }
    };

    const handleModal = (location: ILocation) => {
        setModalVisible(true);
        setRowData(location);
    };

    const handleEditModal = (location: ILocation) => {
        setEditModal(true);
        setRowData(location);
    };

    const menu = (loca: ILocation) => (
        <Menu onClick={() => {}} key={loca.franchiseId}>
            <Menu.Item key="0">
                <a onClick={() => handleModal(loca)}>Ver detalles</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a onClick={() => handleEditModal(loca)}>Editar</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            {loading ? (
                <>
                    <Card loading={loading} style={{ marginBottom: '1.5em' }} />
                    <Card loading={loading} style={{ marginBottom: '1.5em' }} />
                    <Card loading={loading} />
                </>
            ) : (
                <>
                    <div
                        style={{
                            marginBottom: '1em',
                        }}
                    >
                        <CardFilter
                            query={query}
                            changeQuery={searchFilter}
                            placeholder="Filtrar por nombre o telefono"
                        />
                    </div>
                    {data.map((loca: ILocation) => (
                        <Card
                            size="small"
                            style={{ marginBottom: '1.5em' }}
                            key={loca.franchiseId}
                            extra={
                                <Dropdown
                                    overlay={() => menu(loca)}
                                    trigger={['click']}
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        <MoreOutlined />
                                    </a>
                                </Dropdown>
                            }
                        >
                            <Card
                                size="small"
                                type="inner"
                                title="Nombre"
                                bordered={false}
                            >
                                {loca.name}
                            </Card>
                            <Card
                                size="small"
                                type="inner"
                                title="Telefono"
                                bordered={false}
                            >
                                {loca.phoneNumber}
                            </Card>
                            <Card
                                size="small"
                                type="inner"
                                title="Dirección"
                                bordered={false}
                            >
                                {loca.address}
                            </Card>
                        </Card>
                    ))}
                </>
            )}
            <LocationDetailCard
                modalVisible={modalVisible}
                closeModal={() => setModalVisible(false)}
                rowData={rowData}
            />
            <UpdateLocation
                modalVisible={editModal}
                closeModal={() => setEditModal(false)}
                rowData={rowData}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        location: state.GetLocations.location,
        loading: state.GetLocations.loading,
        userAuth: state.IsAuth.userAuth,
    };
};

const mapDispatchToProps = {
    getLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationCard);
