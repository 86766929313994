import { AnyAction } from 'redux';

interface Directors {
    directors: string[];
    loading: boolean;
}

const initialState: Directors = {
    directors: [],
    loading: false,
};

export const GetDirectors = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'GET_DIRECTORS':
            return {
                ...state,
                directors: action.payload,
            };
        case 'LOADING_DIRECTORS':
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
