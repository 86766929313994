import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import Axios from '../../../axios/axios';
import { AxiosError } from 'axios';

export const deleteDirector =
    (
        userId: number,
        email: string,
        name: string,
        lastName: string,
        photo: string,
        phoneNumber: string,
        isActive: number,
        companyId: number,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch({
            type: 'LOADING_UPDATE_DIRECTOR',
            payload: true,
        });

        try {
            await Axios.put('/users', {
                userId,
                email,
                name,
                lastName,
                photo,
                phone: phoneNumber,
                isActive,
                workPosition: 'director',
                companyId,
            });
            notification.success({
                message: 'Administrador eliminado',
                description: '¡El administrador fue eliminado correctamente!',
            });
        } catch (error) {
            const err = error as AxiosError;
            console.log(err.response?.data);

            notification.error({
                message: 'Ocurrio un error.',
                description: '¡No se pudo eliminado el administrador!',
            });
        } finally {
            dispatch({
                type: 'LOADING_UPDATE_DIRECTOR',
                payload: false,
            });
        }
    };
