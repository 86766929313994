import { AnyAction } from 'redux';

interface IResize {
    screenWidth: number;
}

const initialState: IResize = {
    screenWidth: window.innerWidth,
};

export const ScreenResize = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'SCREEN_RESIZE':
            return {
                ...state,
                screenWidth: action.payload,
            };
        default:
            return state;
    }
};
