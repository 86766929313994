import MainLayout from '@layouts/MainLayout';
import Home from '../pages/Home';
import Tasks from '../pages/Tasks';
import Users from '../pages/Users';
import Incidents from '../pages/Incidents';
import Locations from '../pages/Locations';
import Managers from '../pages/Managers';
import Directors from '../pages/Directors';

interface routeType {
    path: string;
    layout: React.FC<any>;
    component: React.FC<any>;
    exact: boolean;
}

const routes: routeType[] = [
    // Change the route when the dashboard already exists
    {
        path: '/',
        layout: MainLayout,
        component: Home,
        exact: true,
    },
    {
        path: '/tasks',
        layout: MainLayout,
        component: Tasks,
        exact: true,
    },
    {
        path: '/incidents',
        layout: MainLayout,
        component: Incidents,
        exact: true,
    },
    {
        path: '/locations',
        layout: MainLayout,
        component: Locations,
        exact: true,
    },
    {
        path: '/employee',
        layout: MainLayout,
        component: Users,
        exact: true,
    },
    {
        path: '/manager',
        layout: MainLayout,
        component: Managers,
        exact: true,
    },
    {
        path: '/director',
        layout: MainLayout,
        component: Directors,
        exact: true,
    },
];

export default routes;
