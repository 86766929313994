import React, { useState, useEffect } from 'react';
import { Button, Input, Popconfirm, Space, Table, Typography } from 'antd';
import { connect } from 'react-redux';
import { RootState } from '@store/index';
import { getManagers } from '@store/Actions/GetManagers';
import { deleteManager } from '@store/Actions/DeleteManager';
import { DeleteTwoTone, EditTwoTone, SearchOutlined } from '@ant-design/icons';
import UpdateDirector from '@components/UpdateManager';
import Cookies from 'js-cookie';

interface Props {
    getManagers: () => void;
    managers: IManagers[];
    loading: boolean;
    deleteManager: (
        userId: number,
        email: string,
        name: string,
        lastName: string,
        photo: string,
        phoneNumber: string,
        franchiseId: number,
        isActive: number,
        companyId: number,
    ) => void;
}

export interface IManagers {
    companyId: number;
    email: string;
    franchise: string;
    franchiseId: number;
    isActive: number;
    isDirector: false;
    lastName: string;
    name: string;
    phoneNumber: string;
    photo: string;
    userId: number;
    nameComplete: string;
}

interface userNames {
    text: string;
    value: string;
}

interface nameFranchises {
    text: string;
    value: string;
}

const ManagerTable: React.FC<Props> = ({
    getManagers,
    managers,
    loading,
    deleteManager,
}): JSX.Element => {
    const data: IManagers[] = [];
    const companyId = Cookies.get('companyId');

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>('');
    const [managersNames, setManagersNames] = useState<userNames[]>([]);
    const [nameFranchise, setNameFranchise] = useState<nameFranchises[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [searchColumn, setSearchColumn] = useState<string>('');

    useEffect(() => {
        getManagers();
    }, []);

    useEffect(() => {
        const managersNames: any[] = [];
        const franchiseName: any[] = [];
        data.map((element: IManagers) => {
            franchiseName.push(element.franchise);
            managersNames.push({ text: element.name, value: element.name });
        });

        setManagersNames(managersNames);
        filterUniqueFranchise(franchiseName);
    }, [managers]);

    const filterUniqueFranchise = (franchise: string[]) => {
        const uniqueFranchise: any[] = [];
        const unique = franchise.filter((c, index) => {
            return franchise.indexOf(c) === index;
        });

        unique.forEach((element) => {
            uniqueFranchise.push({ text: element, value: element });
        });
        setNameFranchise(uniqueFranchise);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    managers.map((manager: IManagers) => {
        if (manager.isActive === 1) {
            data.push({
                ...manager,
                nameComplete: `${manager.name} ${manager.lastName}`,
            });
        }
    });

    const deleteRow = async (manager: any) => {
        await deleteManager(
            manager.userId,
            manager.email,
            manager.name,
            manager.lastName,
            'uri', //photo
            manager.phoneNumber,
            manager.franchiseId,
            0, //isActive
            parseInt(companyId ?? ''),
        );
        getManagers();
    };

    const getColumnSearch = (dataIndex: string, textString: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Escribe aqui el ${textString}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'nameComplete',
            width: '25%',
            editable: true,
            filters: managersNames,
            ...getColumnSearch('nameComplete', 'supervisor'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: '25%',
            editable: true,
            ...getColumnSearch('email', 'correo'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            width: '20%',
            editable: true,
            ...getColumnSearch('phoneNumber', 'telefono'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Locación',
            dataIndex: 'franchise',
            width: '25%',
            editable: true,
            filters: nameFranchise,
            onFilter: (value, record) => record.franchise.indexOf(value) === 0,
            filterSearch: true,
        },
        {
            title: '',
            width: '5%',
            dataIndex: 'operation',
            render: (_: any, record: IManagers) => {
                return (
                    <div className="actions__icon">
                        <Typography.Link
                            onClick={() => {
                                setModalVisible(true);
                                setRowData(record);
                            }}
                        >
                            <EditTwoTone />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title="¿Eliminar usuario?"
                                okText="Ok"
                                cancelText="Cancelar"
                                onConfirm={() => deleteRow(record)}
                            >
                                <DeleteTwoTone />
                            </Popconfirm>
                        </Typography.Link>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Table
                dataSource={data}
                columns={columns}
                size="small"
                loading={loading}
            ></Table>
            <UpdateDirector
                modalVisible={modalVisible}
                closeModal={closeModal}
                rowData={rowData}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        managers: state.GetManagers.managers,
        loading: state.GetManagers.loading,
    };
};

const mapDispatchToProps = {
    getManagers,
    deleteManager,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerTable);
