/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
    Table,
    Popconfirm,
    Form,
    Typography,
    notification,
    Input,
    Space,
    Button,
} from 'antd';
import { EditTwoTone, DeleteTwoTone, SearchOutlined } from '@ant-design/icons';
import { getAllUsers } from '@store/Actions/GetUsers';
import { deleteUser } from '@store/Actions/DeleteUser';
import { RootState } from '@store/index';
import UpdateUser from '@components/UpdateUser';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import './UserTable.css';
import Axios from '../../axios/axios';
// import updateUser from '../../axios/updateUser';

interface User {
    userId: number;
    email: string;
    name: string;
    lastName: string;
    nameComplete: string;
    weekdays: number;
    startShiftTime: string;
    endShiftTime: string;
    shiftSchedule: string;
    startBreakTime: string;
    endBreakTime: string;
    photo: string;
    phoneNumber: string;
    franchiseId: number;
    isActive: number;
    franchise: string;
    isAdmin: number;
    password: string;
}

interface userNames {
    text: string;
    value: string;
}

interface nameFranchises {
    text: string;
    value: string;
}

interface Props {
    getAllUsers: (franchiseId: number) => void;
    users: any;
    loading: boolean;
    deleteUser: (
        userId: number,
        email: string,
        name: string,
        lastName: string,
        startShiftTime: string,
        endShiftTime: string,
        passwd: string,
        photo: string,
        phoneNumber: string,
        franchiseId: number,
        isActive: number,
    ) => void;
    userAuth: {
        companyId: number;
        franchiseId: number;
    };
}

const UserTable: React.FC<Props> = ({
    getAllUsers,
    users,
    loading,
    deleteUser,
    userAuth,
}) => {
    const franchiseId = parseInt(Cookies.get('franchiseId') ?? '');
    const data: User[] = [];
    const [form] = Form.useForm();

    const [nameFranchise, setNameFranchise] = useState<nameFranchises[]>([]);
    const [userNames, setUserNames] = useState<userNames[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [searchColumn, setSearchColumn] = useState<string>('');

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>('');

    const closeModal = () => {
        setModalVisible(false);
    };

    users.map((user: User) => {
        if (user.isActive === 1) {
            data.push({
                ...user,
                nameComplete: `${user.name} ${user.lastName}`,
                shiftSchedule: `${user.startShiftTime} - ${user.endShiftTime}`,
            });
        }
    });

    useEffect(() => {
        getAllUsers(franchiseId);
    }, []);

    useEffect(() => {
        const userNames: any[] = [];
        const franchiseName: any[] = [];
        data.map((element: User) => {
            franchiseName.push(element.franchise);
            userNames.push({ text: element.name, value: element.name });
        });

        setUserNames(userNames);
        filterUniqueFranchise(franchiseName);
    }, [users]);

    const filterUniqueFranchise = (franchise: string[]) => {
        const uniqueFranchise: any[] = [];
        const unique = franchise.filter((c, index) => {
            return franchise.indexOf(c) === index;
        });

        unique.forEach((element) => {
            uniqueFranchise.push({ text: element, value: element });
        });
        setNameFranchise(uniqueFranchise);
    };

    const deleteRow = async (user: User) => {
        await deleteUser(
            user.userId,
            user.email,
            user.name,
            user.lastName,
            user.startShiftTime,
            user.endShiftTime,
            user.photo,
            user.password,
            user.phoneNumber,
            user.franchiseId,
            (user.isActive = 0),
        );
        getAllUsers(userAuth.franchiseId);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearch = (dataIndex: string, textString: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Escribe aqui el ${textString}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'nameComplete',
            width: '20%',
            editable: true,
            filters: userNames,
            ...getColumnSearch('nameComplete', 'colaborador'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: '20%',
            editable: true,
            ...getColumnSearch('email', 'correo'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            width: '15%',
            editable: true,
            ...getColumnSearch('phoneNumber', 'telefono'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Horario',
            dataIndex: 'shiftSchedule',
            width: '15%',
            editable: true,
        },
        {
            title: 'Locación',
            dataIndex: 'franchise',
            width: '20%',
            editable: true,
            filters: nameFranchise,
            onFilter: (value, record) => record.franchise.indexOf(value) === 0,
            filterSearch: true,
        },
        {
            title: '',
            width: '5%',
            dataIndex: 'operation',
            render: (_: any, record: User) => {
                return (
                    <div className="actions__icon">
                        <Typography.Link
                            onClick={() => {
                                setModalVisible(true);
                                setRowData(record);
                            }}
                        >
                            <EditTwoTone />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title="¿Eliminar usuario?"
                                okText="Ok"
                                cancelText="Cancelar"
                                onConfirm={() => deleteRow(record)}
                            >
                                <DeleteTwoTone />
                            </Popconfirm>
                        </Typography.Link>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Form form={form} component={false}>
                <Table
                    dataSource={data}
                    columns={columns}
                    rowClassName="editable-row"
                    size="small"
                    loading={loading}
                />
            </Form>
            <UpdateUser
                modalVisible={modalVisible}
                closeModal={closeModal}
                rowData={rowData}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        users: state.GetUsers.users,
        loading: state.GetUsers.loading,
        userAuth: state.IsAuth.userAuth,
    };
};

const mapDispatchToProps = {
    getAllUsers,
    deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
