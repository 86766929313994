import { AnyAction } from 'redux';

interface Franchise {
    franchise: string[];
}

const initialState: Franchise = {
    franchise: [],
};

export const GetFranchise = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'GET_FRANCHISE':
            return {
                ...state,
                franchise: action.payload,
            };

        default:
            return state;
    }
};
