/* eslint-disable no-useless-escape */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, TimePicker, Radio } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import { RootState } from '@store/index';
import { getFranchise } from '../../core/store/Actions/GetFranchise';
import { updateUser } from '../../core/store/Actions/UpdateUser';
import { getAllUsers } from '../../core/store/Actions/GetUsers';

interface Props {
    modalVisible: boolean;
    closeModal: () => void;
    rowData: {
        email: string;
        endShiftTime: string;
        franchise: string;
        franchiseId: number;
        isActive: number;
        lastName: string;
        name: string;
        nameComplete: string;
        password: string;
        phoneNumber: string;
        photo: string;
        shiftSchedule: string;
        startShiftTime: string;
        userId: number;
    };
    allFranchises: [];
    getFranchise: (companyId: number) => void;
    updateUser: (
        userId: number,
        email: string,
        name: string,
        lastName: string,
        startShiftTime: string,
        endShiftTime: string,
        photo: string,
        phoneNumber: string,
        franchiseId: number,
        isActive: number,
    ) => void;
    loading: boolean;
    getAllUsers: (franchiseId: number) => void;
    userAuth: {
        companyId: number;
        franchiseId: number;
    };
}

const UpdateUser: React.FC<Props> = ({
    modalVisible,
    closeModal,
    rowData,
    allFranchises,
    getFranchise,
    updateUser,
    loading,
    getAllUsers,
    userAuth,
}): JSX.Element => {
    const [franchise, setFranchise] = useState<any>('');
    const [startShiftTime, setStartShiftTime] = useState<any>('');
    const [endShiftTime, setEndShiftTime] = useState<any>('');

    const [form] = Form.useForm();
    const { Option } = Select;

    useEffect(() => {
        getFranchise(userAuth.companyId);
        // setLoadingSelect(true);
        form.setFieldsValue({
            name: rowData.name,
            lastName: rowData.lastName,
            email: rowData.email,
            phone: rowData.phoneNumber,
            franchise: rowData.franchiseId,
            shift: [
                moment(rowData.startShiftTime, 'HH:mm:ss'),
                moment(rowData.endShiftTime, 'HH:mm:ss'),
            ],
        });
    }, [rowData, franchise]);

    const onDestroy = () => {
        form.resetFields();
        closeModal();
    };

    const onOk = async () => {
        const values = await form.validateFields();
        await updateUser(
            rowData.userId,
            values.email !== undefined ? values.email : rowData.email,
            values.name !== undefined ? values.name : rowData.name,
            values.lastName !== undefined ? values.lastName : rowData.lastName,
            startShiftTime !== '' ? startShiftTime : rowData.startShiftTime,
            endShiftTime !== '' ? endShiftTime : rowData.endShiftTime,
            rowData.photo,
            values.phone !== undefined ? values.phone : rowData.phoneNumber,
            values.franchise !== undefined
                ? values.franchise
                : rowData.franchiseId,
            rowData.isActive,
        );
        form.resetFields();
        closeModal();
        getAllUsers(userAuth.franchiseId);
    };

    const handleChange = (value) => {
        setFranchise(value);
    };

    function onChange(time, timeString) {
        setStartShiftTime(timeString[0]);
        setEndShiftTime(timeString[1]);
    }

    const options = allFranchises.map((franchise: any) => (
        <Option
            value={franchise.franchiseId}
            key={franchise.franchiseId}
            label={franchise.name}
        >
            {franchise.name}
        </Option>
    ));

    return (
        <Modal
            visible={modalVisible}
            onCancel={onDestroy}
            cancelText="Cancelar"
            okText="Editar"
            onOk={() => onOk()}
            confirmLoading={loading}
            title={`Editando: ${rowData?.name}`}
        >
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
            >
                <Form.Item name="name" label="Nombre">
                    <Input type="text" placeholder={rowData.name} />
                </Form.Item>
                <Form.Item name="lastName" label="Apellido">
                    <Input type="text" placeholder={rowData.lastName} />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            pattern:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Por favor, ingrese un correo electronico',
                        },
                    ]}
                >
                    <Input type="text" placeholder={rowData.email} />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Telefono"
                    rules={[
                        {
                            // required: true,
                            pattern:
                                /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/,
                            message:
                                'Por favor, ingrese un telefono con 10 digitos',
                        },
                    ]}
                >
                    <Input type="text" placeholder={rowData.phoneNumber} />
                </Form.Item>
                <Form.Item name="franchise" label="Sucursal">
                    <Select
                        placeholder={rowData.franchise}
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        onChange={(value) => handleChange(value)}
                    >
                        {options}
                    </Select>
                </Form.Item>
                <Form.Item name="shift" label="Turno">
                    <TimePicker.RangePicker
                        onChange={onChange}
                        placeholder={[
                            rowData.startShiftTime,
                            rowData.endShiftTime,
                        ]}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        allFranchises: state.GetFranchise.franchise,
        loading: state.UpdateUser.loading,
        userAuth: state.IsAuth.userAuth,
    };
};

const mapDispatchToProps = {
    getFranchise,
    updateUser,
    getAllUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
