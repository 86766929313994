import { Button, Result } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
const NotFound: React.FC = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Result
                status="404"
                title="404"
                subTitle="Lo sentimos, la página que buscas no existe."
                extra={
                    <Link to="./">
                        <Button type="primary">Regresar</Button>
                    </Link>
                }
            />
        </div>
    );
};

export default NotFound;
