import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Dropdown, Menu, Popconfirm } from 'antd';
import { RootState } from '@store/index';
import { getDirectors } from '@store/Actions/GetDirectors';
import { deleteDirector } from '@store/Actions/DeleteDirector';
import { IDirectors } from '@components/DirectorTable';
import NoResults from '@assets/icons/no-results.png';
import { MoreOutlined } from '@ant-design/icons';
import UpdateDirector from '@components/UpdateDirector';
import CardFilter from '@components/CardFilter';
import Cookies from 'js-cookie';

interface Props {
    loading: boolean;
    directors: IDirectors[];
    getDirectors: () => void;
    deleteDirector: (
        userId: number,
        email: string,
        name: string,
        lastName: string,
        photo: string,
        phoneNumber: string,
        isActive: number,
        companyId: number,
    ) => void;
}

const DirectorCard: React.FC<Props> = ({
    loading,
    directors,
    getDirectors,
    deleteDirector,
}): JSX.Element => {
    const data: IDirectors[] = [];
    const companyId = Cookies.get('companyId');

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>('');
    const [query, setQuery] = useState<string>('');
    const [search, setSearch] = useState<any>([]);

    useEffect(() => {
        getDirectors();
    }, []);

    directors.map((manager: IDirectors) => {
        if (manager.isActive === 1) {
            data.push({
                ...manager,
                nameComplete: `${manager.name} ${manager.lastName}`,
            });
        }
    });

    const searchFilter = (search) => {
        setQuery(search);

        const searchResult = data.filter(
            (query: IDirectors) =>
                query.nameComplete
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                query.email.toLowerCase().includes(search.toLowerCase()) ||
                query.phoneNumber.includes(search),
        );

        if (search.length > 0) {
            setSearch(searchResult);
        } else {
            setSearch(data);
        }
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleUpdate = (director) => {
        setModalVisible(true);
        setRowData(director);
    };

    const handleDelete = async (director: IDirectors) => {
        await deleteDirector(
            director.userId,
            director.email,
            director.name,
            director.lastName,
            director.photo,
            director.phoneNumber,
            0, //isActive
            parseInt(companyId ?? ''),
        );
        getDirectors();
    };

    const menu = (director: IDirectors) => (
        <Menu onClick={() => {}} key={director.userId}>
            <Menu.Item key="0">
                <a onClick={() => handleUpdate(director)}>Editar</a>
            </Menu.Item>
            <Menu.Item key="1">
                <Popconfirm
                    title="¿Eliminar usuario?"
                    onConfirm={() => handleDelete(director)}
                    okText="Yes"
                    cancelText="No"
                >
                    <a onClick={() => {}}>Eliminar</a>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            {loading ? (
                <>
                    <Card loading={loading} style={{ marginBottom: '1.5em' }} />
                    <Card loading={loading} style={{ marginBottom: '1.5em' }} />
                    <Card loading={loading} />
                </>
            ) : (
                <>
                    <div
                        style={{
                            marginBottom: '1em',
                        }}
                    >
                        <CardFilter
                            query={query}
                            changeQuery={searchFilter}
                            placeholder="Filtrar por nombre, telefono y correeo"
                        />
                    </div>
                    {search.length !== 0 ? (
                        search.map((directors: IDirectors) => (
                            <Card
                                style={{ marginBottom: '1.5em' }}
                                key={directors.userId}
                                extra={
                                    <Dropdown
                                        overlay={() => menu(directors)}
                                        trigger={['click']}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>
                                            <MoreOutlined />
                                        </a>
                                    </Dropdown>
                                }
                            >
                                <Card
                                    type="inner"
                                    title="Nombre"
                                    bordered={false}
                                >
                                    {directors.nameComplete}
                                </Card>
                                <Card
                                    type="inner"
                                    title="Correo"
                                    bordered={false}
                                >
                                    {directors.email}
                                </Card>
                                <Card
                                    type="inner"
                                    title="Telefono"
                                    bordered={false}
                                >
                                    {directors.phoneNumber}
                                </Card>
                            </Card>
                        ))
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={NoResults}
                                style={{
                                    height: 200,
                                    width: 200,
                                    margin: '0 auto',
                                }}
                            />
                            <span style={{ color: 'grey' }}>
                                ¡Aun no hay administradores!
                            </span>
                        </div>
                    )}
                </>
            )}
            <UpdateDirector
                modalVisible={modalVisible}
                closeModal={closeModal}
                rowData={rowData}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        directors: state.GetDirectors.directors,
        loading: state.GetDirectors.loading,
    };
};

const mapDispatchToProps = {
    getDirectors,
    deleteDirector,
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectorCard);
