import BasicLayout from '@layouts/BasicLayout';
import Login from '@pages/Login';
import RecoverPassword from '@pages/RecoverPassword';
import CompareCode from '@pages/CompareCode';
import UpdatePassword from '@pages/UpdatePassword';

interface routeType {
    path: string;
    layout: React.FC<any>;
    component: React.FC<any>;
    exact: boolean;
}

const routes: routeType[] = [
    {
        path: '/',
        layout: BasicLayout,
        component: Login,
        exact: true,
    },
    {
        path: '/recover-email',
        layout: BasicLayout,
        component: RecoverPassword,
        exact: true,
    },
    {
        path: '/recover-code',
        layout: BasicLayout,
        component: CompareCode,
        exact: true,
    },
    {
        path: '/recover-password',
        layout: BasicLayout,
        component: UpdatePassword,
        exact: true,
    },
];

export default routes;
