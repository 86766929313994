import React, { useState, Fragment } from 'react';
import { Col, Form, InputNumber as Input, Row, Select, Switch } from 'antd';

interface Props {
    field: {
        key: number;
        name: number;
        fieldKey: number;
    };
}

export const SelectInput: React.FC<Props> = ({ field }): JSX.Element => {
    const [inputType, setInputType] = useState<number>(0);
    const [isRequired, setIsRequired] = useState<boolean>(true);
    const { Option } = Select;

    return (
        <Fragment>
            <Row gutter={24}>
                <Col xs={24} sm={19}>
                    <Form.Item
                        name={[field.name, 'inputFieldId']}
                        fieldKey={field.fieldKey}
                        label="Tipo de campo"
                        rules={[
                            {
                                required: true,
                                message: 'Ingrese el tipo de campo',
                            },
                        ]}
                    >
                        <Select
                            onChange={(input: number) => setInputType(input)}
                            placeholder="Ingrese un tipo de campo"
                        >
                            <Option value={1} key="inputText">
                                Campo de texto
                            </Option>
                            <Option value={2} key="inputPhoto">
                                Campo de foto
                            </Option>
                            <Option value={3} key="inputFile">
                                Campo de archivo
                            </Option>
                            <Option value={4} key="inputLocation">
                                Campo de ubicacion
                            </Option>
                            <Option value={5} key="inputNumber">
                                Campo de numero
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={5}>
                    <Form.Item
                        name={[field.name, 'isRequired']}
                        label="Requerido"
                        fieldKey={field.fieldKey}
                        initialValue={true}
                    >
                        <Switch checked={isRequired} onChange={setIsRequired} />
                    </Form.Item>
                </Col>
            </Row>

            {inputType !== 1 && inputType !== 4 && inputType !== 5 && (
                <div>
                    <Row>
                        <Col xs={6}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label="Minimo"
                                name={[field.name, 'min']}
                                fieldKey={field.fieldKey}
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Ingrese un numero min para esa tarea',
                                    },
                                ]}
                            >
                                <Input placeholder="min" />
                            </Form.Item>
                        </Col>
                        <Col xs={6}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label="Maximo"
                                name={[field.name, 'max']}
                                fieldKey={field.fieldKey}
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Ingrese un numero max para esa tarea',
                                    },
                                ]}
                            >
                                <Input placeholder="max" />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            )}
        </Fragment>
    );
};
