import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import Axios from '../../../axios/axios';

export const deleteUser =
    (
        userId: number,
        email: string,
        name: string,
        lastName: string,
        startShiftTime: string,
        endShiftTime: string,
        photo: string,
        passwd: string,
        phoneNumber: string,
        franchiseId: number,
        isActive: number,
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch({
            type: 'LOADING_UPDATE_USER',
            payload: true,
        });

        try {
            const response = await Axios.put('/users', {
                userId,
                email,
                name,
                lastName,
                startShiftTime,
                endShiftTime,
                passwd,
                photo,
                phone: phoneNumber,
                franchiseId,
                isActive,
                workPosition: 'employee',
            });

            notification.success({
                message: 'Usuario eliminado',
                description: '¡El usuario fue eliminado correctamente!',
            });
            dispatch({
                type: 'LOADING_UPDATE_USER',
                payload: false,
            });
        } catch (error) {
            notification.error({
                message: 'Ocurrio un error.',
                description: '¡No se pudo eliminar el usuario!',
            });
            dispatch({
                type: 'LOADING_UPDATE_USER',
                payload: false,
            });
            console.log(error, 'UPDATE USER ERROR');
        }
    };
