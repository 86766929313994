/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useRef } from 'react';
import {
    Table,
    Input,
    Form,
    Typography,
    Tag,
    Space,
    Button,
    Modal,
} from 'antd';
import { EditTwoTone, SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { RootState } from '@store/index';
import { getTasks } from '@store/Actions/GetTasks';
import { ITask } from './types';
import UpdateTask from '@components/UpdateTask';
import useFilter from '../../hooks/useFilter';
import useFilterUnique from '../../hooks/useFilterUnique';

import './TaskTable.css';

interface userNames {
    text: string;
    value: string;
}

interface Props {
    tasks: any;
    getTasks: () => void;
    loading: boolean;
}

const TaskTable: React.FC<Props> = ({
    tasks,
    getTasks,
    loading,
}): JSX.Element => {
    const dataTask: ITask[] = [];

    const userName = useFilterUnique({ data: tasks });

    const { getColumnSearch } = useFilter();
    const franchiseId = Cookies.get('franchiseId');
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState<any>('');

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [rowData, setRowData] = useState<ITask>({
        taskConfId: 0,
        description: '',
        taskConfName: '',
        duration: 0,
        isCompleted: 0,
        isOnTime: 0,
        isNew: 0,
        createdDate: 0,
        finishedDate: 0,
        tasks: [
            {
                userName: '',
                userLastName: '',
                taskConfId: 0,
                userId: 0,
                createdDate: 0,
                finishedDate: 0,
                isCompleted: 0,
                isOnTime: 0,
                isNew: 0,
                taskId: 0,
                franchiseId: 0,
                franchiseName: '',
                taskConfName: '',
                description: '',
                duration: 0,
                fieldId: 0,
                inputTypeId: 0,
                inputName: '',
                isRequired: 0,
                min: 0,
                max: 0,
                fieldName: '',
                value: '',
            },
        ],
        nameComplete: '',
        status: '',
        key: 0,
    });

    const closeModal = () => {
        setModalVisible(false);
    };

    useEffect(() => {
        getTasks();
    }, []);

    const statusTask = (onTime: number, completed: number): string => {
        let status = 'No completado';

        if (onTime == 1 && completed == 1) {
            status = 'Completado';
        }

        if (onTime == 0 && completed == 1) {
            status = 'No completado';
        }

        if (onTime == 1 && completed == 0) {
            status = 'En progreso';
        }

        return status;
    };

    tasks.forEach((task: ITask) => {
        dataTask.push({
            ...task,
            key: task.taskConfId,
            nameComplete: `${task.tasks[0].userName} ${task.tasks[0].userLastName}`,
            status: statusTask(task.isOnTime, task.isCompleted),
        });
    });

    const statusColor = (status): string => {
        let color = 'red';
        if (status === 'En progreso') {
            color = 'blue';
        }

        if (status === 'Completado') {
            color = 'green';
        }

        if (status === 'No completado') {
            color = 'red';
        }

        return color;
    };

    const expandedRowRender = (args) => {
        const columns = [
            { title: 'Campo', dataIndex: 'fieldName', key: 'fieldName' },
            {
                title: 'Tipo de campo',
                dataIndex: 'inputName',
                key: 'inputName',
            },
            {
                title: 'Locacion',
                dataIndex: 'franchiseName',
                key: 'franchiseName',
            },
        ];

        return (
            <div
                style={{
                    paddingLeft: '2em',
                    margin: '1em',
                }}
            >
                <Table
                    columns={columns}
                    dataSource={args.tasks}
                    pagination={false}
                />
            </div>
        );
    };

    const columns = [
        {
            title: 'Nombre',
            key: 'name',
            dataIndex: 'taskConfName',
            width: '20%',
            editable: true,
            ...getColumnSearch('taskConfName', 'nombre'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Descripción',
            key: 'description',
            dataIndex: 'description',
            width: '25%',
            editable: true,
        },
        {
            title: 'Usuario',
            key: 'nameComplete',
            dataIndex: 'nameComplete',
            width: '20%',
            editable: true,
            filters: userName.items,
            onFilter: (value, record) =>
                record.nameComplete.indexOf(value) === 0,
            filterSearch: true,
        },
        {
            title: '',
            key: 'status',
            dataIndex: 'status',
            width: '10%',
            editable: true,
            align: 'center',
            filters: [
                {
                    text: 'En progreso',
                    value: 'En progreso',
                },
                {
                    text: 'Completado',
                    value: 'Completado',
                },
                {
                    text: 'No completado',
                    value: 'No completado',
                },
            ],
            onFilter: (value: string, record: any) => record.status === value,
            // filterSearch: true,
            render: (status) => <Tag color={statusColor(status)}>{status}</Tag>,
        },
        {
            key: 'evidence',
            title: 'Evidencia',
            dataIndex: 'evidence',
            width: '10%',
            editable: false,
        },
        {
            key: 'operation',
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: ITask) => (
                <div className="actions__icon">
                    <Typography.Link
                        disabled={editingKey !== ''}
                        onClick={() => {
                            setModalVisible(true);
                            setRowData(record);
                        }}
                    >
                        <EditTwoTone />
                    </Typography.Link>
                </div>
            ),
        },
    ];

    return (
        <>
            {/* <Form form={form} component={false}> */}
            <Table
                size="small"
                dataSource={dataTask}
                loading={loading}
                // @ts-ignore
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => expandedRowRender(record),
                }}
            />
            {/* </Form> */}
            <UpdateTask
                modalVisible={modalVisible}
                rowData={rowData}
                closeModal={closeModal}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        tasks: state.GetTasks.tasks,
        loading: state.GetTasks.loading,
    };
};

const mapDispatchToProps = {
    getTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTable);
