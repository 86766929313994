import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Space, Typography } from 'antd';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { ILocation } from '@components/LocationCard';
import './index.css';

interface IProps {
    modalVisible: boolean;
    closeModal: () => void;
    rowData: ILocation;
}

const LocationDetailCard: React.FC<IProps> = ({
    modalVisible,
    closeModal,
    rowData,
}): JSX.Element => {
    const { Text } = Typography;

    const [position, setPosition] = useState({
        lat: 0,
        lng: 0,
    });

    useEffect(() => {
        setPosition({ lat: rowData.lat, lng: rowData.lng });
    }, [rowData]);

    const handleClose = () => {
        closeModal();
        setPosition({ lat: 0, lng: 0 });
    };

    return (
        <Modal
            visible={modalVisible}
            onCancel={() => handleClose()}
            title={`Información de ${rowData.name}`}
            footer={null}
            width="100%"
            destroyOnClose={true}
        >
            <div>
                <div className="container__modal">
                    <LoadScript
                        // @ts-ignore
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    >
                        <GoogleMap
                            mapContainerStyle={{
                                width: '100%',
                                height: '100%',
                            }}
                            center={{ lat: rowData.lat, lng: rowData.lng }}
                            zoom={18}
                            options={{
                                streetViewControl: false,
                                fullscreenControl: false,
                                mapTypeControl: false,
                                zoomControl: false,
                            }}
                        >
                            <Marker position={position} />
                        </GoogleMap>
                    </LoadScript>
                </div>
                <div className="container__cardDetails">
                    <Space direction="vertical">
                        <Text strong>Nombre</Text>
                        <Text type="secondary">{rowData.name}</Text>

                        <Text strong>Telefono</Text>
                        <Text type="secondary">{rowData.phoneNumber}</Text>

                        <Text strong>Ubicación</Text>
                        <Text type="secondary">{rowData.address}</Text>
                    </Space>
                </div>
            </div>
        </Modal>
    );
};

export default LocationDetailCard;
