import { AnyAction } from 'redux';

interface IActiveUser {
    activeUsers: [];
}

const initialState: IActiveUser = {
    activeUsers: [],
};
export const ActiveUsers = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'GET_ACTIVE_USERS':
            const users: any = [];

            action.payload.forEach((element: any) => {
                users.push({
                    ...element,
                    nameComplete: `${element.name} ${element.lastName}`,
                    shiftSchedule: `${element.startShiftTime} - ${element.endShiftTime}`,
                });
            });

            return {
                ...state,
                activeUsers: users,
            };
        default:
            return state;
    }
};
