import Cookies from 'js-cookie';
import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

// require('dotenv').config();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const apiInstance = axios.create();

apiInstance.interceptors.request.use(
    async (config) => {
        const token = await Cookies.get('token');
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    (error) => {
        console.log('ERROR TASK');

        return Promise.reject(error);
    },
);

export default apiInstance;
