import React from 'react';
import { Statistic, Card } from 'antd';

type Props = {
    title: string;
    value: string | number;
    color: string;
    suffix?: string;
    precision?: number;
    prefix?: any;
};

const Kpi: React.FC<Props> = ({
    title,
    value,
    color,
    suffix,
    precision,
    prefix,
}) => {
    return (
        <Card
            style={{
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Statistic
                title={title}
                precision={precision}
                value={value}
                valueStyle={{ color: color }}
                suffix={suffix}
                prefix={prefix}
            />
        </Card>
    );
};

export default Kpi;
