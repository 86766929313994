import { useState } from 'react';

type index = number;
type value = boolean;

export const useShowItem = () => {
    const [show, setShow] = useState(Array().map(() => false));

    const handleChange = (idx: index, value: value) => {
        const newState = [...show];
        newState[idx] = value;

        setShow(newState);
    };

    return { show, handleChange };
};
