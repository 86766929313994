import Cookies from 'js-cookie';
// import Logout from '../../Apis/Logout';
import Axios from '../../../axios/axios';

export const LogOut = () => async () => {
    const tokenId = Cookies.get('token');

    try {
        Cookies.remove('token');

        await Axios.post('/logout', {
            tokenId,
        });
    } catch (error) {
        console.log(error, 'error logout');
    }
};
