import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import Cookies from 'js-cookie';
import Axios from '../../../axios/axios';

export const getTasks = () => async (dispatch: AppDispatch) => {
    const franchiseId = Cookies.get('franchiseId');
    const companyId = Cookies.get('companyId');
    const isDirector = Cookies.get('isDirector');

    let url = '';

    if (isDirector) {
        url = `companyId=${companyId}`;
    } else {
        url = `franchiseId=${franchiseId}`;
    }

    dispatch({
        type: 'LOADING_TASKS',
        payload: true,
    });

    try {
        let response = await Axios.get(`/tasks?${url}`);

        if (response.data.message !== 'There are not tasks to show') {
            response.data = response.data;
        } else {
            response.data = [];
        }

        dispatch({
            type: 'GET_TASKS',
            payload: response?.data.reverse(),
        });
        dispatch({
            type: 'GET_TASKS_CARD',
            payload: response.data,
        });

        dispatch({
            type: 'LOADING_TASKS',
            payload: false,
        });
    } catch (error) {
        dispatch({
            type: 'LOADING_TASKS',
            payload: false,
        });
        notification.error({
            message: 'Ocurrió un error.',
            description: '¡No se pudieron cargar las tareas!',
        });
        console.log(error);
    }
};
