import React from 'react';
import { Modal, Button, Image } from 'antd';
import './styles.css';

interface IImageView {
    modalVisible: boolean;
    setModalVisible: () => void;
    url: string[];
}

const ImageView: React.FC<IImageView> = ({
    modalVisible,
    setModalVisible,
    url,
}): JSX.Element => {
    return (
        <Modal
            visible={modalVisible}
            onOk={() => setModalVisible()}
            onCancel={() => setModalVisible()}
            closable={false}
            width="60%"
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => setModalVisible()}
                >
                    Ok
                </Button>,
            ]}
        >
            <div className="image__container">
                {url?.map((photo: string, index: number) => (
                    <Image
                        src={photo}
                        width="auto"
                        height="auto"
                        key={`key-${index}`}
                    />
                ))}
            </div>
        </Modal>
    );
};

export default ImageView;
