import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { RootState } from '@store/index';
import { verifyToken } from '@store/Actions/Login';
import { verifyAuth } from '@store/Actions/Login';
import { screenResize } from '@store/Actions/ScreenResize';
import Navigation from './navigation/Navigation';
require('dotenv').config();
interface IApp {
    verifyToken: () => void;
    verifyAuth: (token: string) => void;
    isAuthenticated: boolean;
    screenResize: (screenWidth: number) => void;
}

const App: React.FC<IApp> = ({
    isAuthenticated,
    verifyToken,
    verifyAuth,
    screenResize,
}): JSX.Element => {
    useEffect(() => {
        verifyToken();
        const token = Cookies.get('token');
        verifyAuth(token ?? '');

        window.addEventListener('resize', handleWindowSizeChange);
        return () =>
            window.removeEventListener('resize', handleWindowSizeChange);
    }, [isAuthenticated]);

    const handleWindowSizeChange = () => {
        screenResize(window.innerWidth);
    };

    return <Navigation isAuthenticated={isAuthenticated} />;
};

const mapStateToProps = (state: RootState) => {
    return {
        isAuthenticated: state.Login.isAuthenticated,
    };
};

const mapDispatchToProps = {
    verifyToken,
    verifyAuth,
    screenResize,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
