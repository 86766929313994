import { notification } from 'antd';
import { AxiosError } from 'axios';
import Axios from '../../../axios/axios';

export const createNewLocation =
    (
        companyId: number,
        name: string,
        phone: string,
        lat: number,
        lng: number,
        address: string,
    ) =>
    async (dispatch) => {
        dispatch({
            type: 'LOADING_NEW_LOCATION',
            payload: true,
        });
        console.log({ companyId, name, phone, lat, lng, address }, 'DATA');

        try {
            await Axios.post('/franchises', {
                companyId,
                name,
                phone,
                lat,
                lng,
                address,
            });

            notification.success({
                message: 'Locación creada.',
                description: '¡La locación ha sido creada exitosamente!',
            });
        } catch (error) {
            const err = error as AxiosError;
            console.log(err.response?.data);
            notification.error({
                message: 'Locación fallida.',
                description: '¡No se ha podido crear la locación!',
            });
        } finally {
            dispatch({
                type: 'LOADING_NEW_LOCATION',
                payload: false,
            });
        }
    };
