import { AnyAction } from 'redux';

interface Users {
    users: string[];
    loading: boolean;
}

const initialState: Users = {
    users: [],
    loading: false,
};

export const GetUsers = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'GET_USERS':
            return {
                ...state,
                users: action.payload,
            };
        case 'LOADING_USERS':
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
