/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react';
import {
    Table,
    Form,
    Typography,
    notification,
    Popconfirm,
    Input,
    Space,
    Button,
    Tag,
    Row,
    Col,
} from 'antd';
import {
    EditTwoTone,
    DeleteTwoTone,
    EyeOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import LocationDetail from '@components/LocationDetail';
import Cookies from 'js-cookie';
import { RootState } from '@store/index';
import { getLocations } from '@store/Actions/GetLocactions';
import { deleteLocation } from '@store/Actions/DeleteLocation';
import UpdateLocation from '@components/UpdateLocation';
import './LocationTable.css';

interface IProps {
    location: Item[];
    loading: boolean;
    getLocations: (companyId: number) => void;
    deleteLocation: (
        phone: string,
        lat: number,
        lng: number,
        name: string,
        franchiseId: number,
        address: string,
        isActive: number,
    ) => void;
    userAuth: {
        companyId: number;
    };
}
interface Item {
    comapanyId: number;
    franchiseId: number;
    lat: number;
    lng: number;
    name: string;
    phoneNumber: string;
    address: string;
    isActive: number;
}

interface userNames {
    text: string;
    value: string;
}

const LocationTable: React.FC<IProps> = ({
    location,
    loading,
    getLocations,
    deleteLocation,
    userAuth,
}): JSX.Element => {
    const companyId = parseInt(Cookies.get('companyId') ?? '');

    const [form] = Form.useForm();
    const data: Item[] = [];
    const [userNames, setUserNames] = useState<userNames[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [detailModal, setDetailModal] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [searchColumn, setSearchColumn] = useState<string>('');
    const [rowData, setRowData] = useState<Item>({
        comapanyId: 0,
        franchiseId: 0,
        lat: 0,
        lng: 0,
        name: '',
        phoneNumber: '',
        address: '',
        isActive: 0,
    });

    location.map((loca: Item) => {
        if (loca.isActive === 1) {
            data.push({
                ...loca,
            });
        }
    });

    useEffect(() => {
        getLocations(companyId);
    }, []);

    useEffect(() => {
        const userNames: any[] = [];
        location.map((element) => {
            userNames.push(element.name);
        });
        filterUniqueNames(userNames);
    }, [location]);

    const filterUniqueNames = (name: string[]) => {
        const uniqueNames: any[] = [];
        const unique = name.filter((c, index) => {
            return name.indexOf(c) === index;
        });

        unique.forEach((element) => {
            uniqueNames.push({ text: element, value: element });
        });
        setUserNames(uniqueNames);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const deleteRow = async (rowData: Item) => {
        await deleteLocation(
            rowData.phoneNumber,
            rowData.lat,
            rowData.lng,
            rowData.name,
            rowData.franchiseId,
            rowData.address,
            0, //isActive
        );
        getLocations(userAuth.companyId);
    };

    const getColumnSearch = (dataIndex: string, textString: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Escribe aqui el ${textString}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            width: '25%',
            editable: true,
            ...getColumnSearch('name', 'nombre'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Dirección',
            dataIndex: 'address',
            width: '25%',
            editable: true,
            ...getColumnSearch('address', 'direccion'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            width: '15%',
            editable: true,
            ...getColumnSearch('phoneNumber', 'telefono'),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
        },
        {
            title: 'Gerentes',
            dataIndex: 'managers',
            width: '25%',
            render: (managers) =>
                managers.map((manager, idx) => {
                    console.log(idx, 'idx');

                    let color = 'blue';
                    if (idx == 0) color = 'blue';
                    if (idx == 1) color = 'green';
                    if (idx == 2) color = 'red';
                    if (idx == 3) color = 'orange';
                    if (idx == 4) color = 'yellow';

                    return (
                        <Tag
                            style={{ margin: '3px' }}
                            color={color}
                            key={manager}
                        >
                            {manager}
                        </Tag>
                    );
                }),

            editable: true,
        },
        {
            title: '',
            dataIndex: 'operation',
            width: '15%',
            render: (_: any, record: Item) => (
                <div className="actions__icon">
                    <Typography.Link
                        onClick={() => {
                            setDetailModal(true);
                            setRowData(record);
                        }}
                    >
                        <EyeOutlined />
                    </Typography.Link>
                    <Typography.Link
                        onClick={() => {
                            setModalVisible(true);
                            setRowData(record);
                        }}
                    >
                        <EditTwoTone />
                    </Typography.Link>
                    {/* <Typography.Link>
                        <Popconfirm
                            title="Eliminar"
                            onConfirm={() => deleteRow(record)}
                        >
                            <DeleteTwoTone />
                        </Popconfirm>
                    </Typography.Link> */}
                </div>
            ),
        },
    ];

    console.log(data, 'data franch');

    return (
        <>
            <Form form={form} component={false}>
                <Table
                    size="small"
                    dataSource={data}
                    loading={loading}
                    // @ts-ignore
                    columns={columns}
                    rowClassName="editable-row"
                />
            </Form>
            <LocationDetail
                modalVisible={detailModal}
                rowData={rowData}
                closeModal={() => setDetailModal(false)}
            />
            <UpdateLocation
                modalVisible={modalVisible}
                closeModal={() => setModalVisible(false)}
                rowData={rowData}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        location: state.GetLocations.location,
        loading: state.GetLocations.loading,
        userAuth: state.IsAuth.userAuth,
    };
};

const mapDispatchToProps = {
    getLocations,
    deleteLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationTable);
