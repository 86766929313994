import { AnyAction } from 'redux';

interface IRecoverPassword {
    emailCode: [];
    loading: boolean;
}

const initialState: IRecoverPassword = {
    emailCode: [],
    loading: false,
};
export const RecoverPassword = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'GET_CODE_DATA':
            return {
                ...state,
                emailCode: action.payload,
            };
        case 'LOADING_UPDATE_PASS':
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
