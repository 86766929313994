import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Menu, Skeleton, Image, Button } from 'antd';
import { RootState } from '@store/index';
import { connect } from 'react-redux';
import { getIncidents } from '@store/Actions/GetIncidents';
import { DownOutlined } from '@ant-design/icons';
import { updateIncidents } from '@store/Actions/UpdateIncidents';
import Cookies from 'js-cookie';
import ImageView from '@components/ImageView';
import CardFilter from '@components/CardFilter';
import NoResults from '@assets/icons/no-results.png';

interface IProps {
    getIncidents: () => void;
    loading: boolean;
    incidents: [];
    updateIncidents: (id: number, newValue: string) => void;
    incidencesCard: IIncidence[];
}

interface IIncidence {
    companyId: number;
    description: string;
    email: string;
    endShiftTime: string;
    franchiseName: string;
    incidenceId: number;
    isActive: number;
    isNew: number;
    lastName: string;
    name: string;
    phone: string;
    photos: string[];
    startShiftTime: string;
    status: string;
    title: string;
    userId: number;
}

const IncidencesCard: React.FC<IProps> = ({
    loading,
    incidents,
    updateIncidents,
    incidencesCard,
}): JSX.Element => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [url, setUrl] = useState<string[]>([]);
    const [query, setQuery] = useState<string>('');
    const [data, setData] = useState<IIncidence[]>([]);

    const setModal = () => {
        setModalVisible(!modalVisible);
    };

    const handlePhotoData = (photos) => {
        setUrl(photos);
        setModalVisible(!modalVisible);
    };

    useEffect(() => {
        getIncidents();
    }, []);

    const menu = (id) => (
        <Menu onClick={(status) => handleUpdate(status.key, id)}>
            <Menu.Item key="Success">Completado</Menu.Item>
            <Menu.Item key="In progress">En progreso</Menu.Item>
        </Menu>
    );

    const handleUpdate = async (status, id) => {
        await updateIncidents(id, status);
        getIncidents();
    };

    const searchFilter = (search) => {
        setQuery(search);

        const searchResult = incidencesCard.filter(
            (query: any) =>
                query.name.toLowerCase().includes(search.toLowerCase()) ||
                query.description
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                query.title.toLowerCase().includes(search.toLowerCase()) ||
                query.status.toLowerCase().includes(search.toLowerCase()),
        );

        if (search.length > 0) {
            setData(searchResult);
        } else {
            setData(incidencesCard);
        }
    };

    return (
        <div>
            {loading ? (
                <>
                    <Card loading={loading} style={{ marginBottom: '1.5em' }} />
                    <Card loading={loading} style={{ marginBottom: '1.5em' }} />
                    <Card loading={loading} />
                </>
            ) : (
                <>
                    <div
                        style={{
                            marginBottom: '1em',
                        }}
                    >
                        <CardFilter
                            query={query}
                            changeQuery={searchFilter}
                            placeholder="Filtrar por nombre, titulo, descripcion o estado"
                        />
                    </div>
                    {data.length !== 0 ? (
                        data.map((incidence) => (
                            <Card
                                style={{ marginBottom: '1.5em' }}
                                key={incidence.incidenceId}
                            >
                                <Skeleton loading={loading} active>
                                    <Card
                                        type="inner"
                                        title="Nombre"
                                        bordered={false}
                                    >
                                        {incidence.name}
                                    </Card>
                                    <Card
                                        type="inner"
                                        title="Titulo"
                                        bordered={false}
                                    >
                                        {incidence.title}
                                    </Card>
                                    <Card
                                        type="inner"
                                        title="Descripción"
                                        bordered={false}
                                    >
                                        {incidence.description}
                                    </Card>
                                    <Card
                                        type="inner"
                                        title="Fotos"
                                        bordered={false}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {incidence.photos.length > 0 ? (
                                                <>
                                                    <Image
                                                        src={
                                                            incidence.photos[0]
                                                        }
                                                        style={{
                                                            width: 25,
                                                            height: 25,
                                                        }}
                                                    />
                                                    <a
                                                        style={{
                                                            marginLeft: '1em',
                                                            color: 'blue',
                                                        }}
                                                        onClick={() =>
                                                            handlePhotoData(
                                                                incidence.photos,
                                                            )
                                                        }
                                                    >{`Ver todas las fotos. (${incidence.photos.length})`}</a>
                                                </>
                                            ) : (
                                                <span>Sin foto</span>
                                            )}
                                        </div>
                                    </Card>
                                    <Card
                                        type="inner"
                                        title="Estado"
                                        bordered={false}
                                    >
                                        <Dropdown
                                            overlay={() =>
                                                menu(incidence.incidenceId)
                                            }
                                            trigger={['click']}
                                        >
                                            <Button
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                                size="middle"
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                {incidence.status}
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </Card>
                                </Skeleton>
                            </Card>
                        ))
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={NoResults}
                                style={{
                                    height: 200,
                                    width: 200,
                                    margin: '0 auto',
                                }}
                            />
                            <span style={{ color: 'grey' }}>
                                ¡Aun no hay tareas!
                            </span>
                        </div>
                    )}
                    <ImageView
                        modalVisible={modalVisible}
                        setModalVisible={setModal}
                        url={url}
                    />
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.GetIncidents.loading,
        incidents: state.GetIncidents.incidents,
        incidencesCard: state.GetIncidents.incidencesCard,
    };
};

const mapDispatchToProps = {
    getIncidents,
    updateIncidents,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidencesCard);
