import React from 'react';
import { ArrowUpOutlined } from '@ant-design/icons';
import ChartLine from '@components/ChartLine';
import Kpi from './Kpi';
import List from './List';
import './dashboard.css';

type Props = {};

const data1 = [
    {
        title: 'This is a title 1',
    },
    {
        title: 'This is a title 2',
    },
    {
        title: 'This is a title 3',
    },
    {
        title: 'This is a title 4',
    },
];
const data2 = [
    {
        title: 'This is a title 1',
    },
    {
        title: 'This is a title 2',
    },
    {
        title: 'This is a title 3',
    },
    {
        title: 'This is a title 4',
    },
    {
        title: 'This is a title 5',
    },
];

const Dashboard: React.FC = (props: Props) => {
    return (
        <div className="Dashboard__container">
            {/* KPIS */}
            <div className="Dashboard__container_kpi">
                <div className="Dashboard__kpi">
                    <Kpi title="Sucursales" color="blue" value={5} />
                </div>
                <div className="Dashboard__kpi">
                    <Kpi title="Gerentes" color="blue" value={10} />
                </div>
                <div className="Dashboard__kpi">
                    <Kpi title="Empleados" color="red" value={10} />
                </div>
                <div className="Dashboard__kpi">
                    <Kpi
                        title="Tareas semanal"
                        color="red"
                        value={20}
                        precision={1}
                        prefix={<ArrowUpOutlined />}
                    />
                </div>
                <div className="Dashboard__kpi">
                    <Kpi
                        title="% completadas"
                        color="green"
                        value={80}
                        precision={1}
                        suffix="%"
                    />
                </div>
            </div>
            {/* Charts */}
            <div className="Dashboard__container_charts">
                <div className="Dashboard__container_list">
                    <div className="Dashboard__list">
                        <List
                            data={data1}
                            title="Top 5 mejores"
                            colorTitle="#39811d"
                            colorName="#39811d"
                        />
                    </div>
                    <div className="Dashboard__list">
                        <List
                            data={data2}
                            title="Top 5 peores"
                            colorTitle="red"
                            colorName="red"
                        />
                    </div>
                </div>
                <div
                    style={{
                        // background: 'white',
                        width: '49%',
                    }}
                >
                    <ChartLine />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
