import { AnyAction } from 'redux';

interface Managers {
    managers: string[];
    loading: boolean;
}

const initialState: Managers = {
    managers: [],
    loading: false,
};

export const GetManagers = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'GET_MANAGERS':
            return {
                ...state,
                managers: action.payload,
            };
        case 'LOADING_MANAGERS':
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
