import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { RootState } from '@store/index';
import LayoutHeader from '@components/LayoutHeader';
import DirectorTable from '@components/DirectorTable';
import { getDirectors } from '@store/Actions/GetDirectors';
import CreateDirector from '@components/CreateDirector';

import './index.css';
import DirectorCard from '@components/DirectorCard';

interface Props {
    screenWidth: number;
    getDirectors: () => void;
}

const Directors: React.FC<Props> = ({
    screenWidth,
    getDirectors,
}): JSX.Element => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const handleModal = (e) => {
        e.preventDefault();
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div className="director__container">
            <div className="director__header">
                <LayoutHeader title="Usuarios" subTitle="Administrador" />
                <Space>
                    <Button
                        type="default"
                        style={{ width: 50 }}
                        icon={<ReloadOutlined />}
                        onClick={() => getDirectors()}
                    />

                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={(e) => {
                            handleModal(e);
                        }}
                        style={screenWidth <= 512 ? { width: 50 } : {}}
                    >
                        {screenWidth > 512 && 'Agregar Administrador'}
                    </Button>
                </Space>
            </div>
            <CreateDirector
                modalVisible={modalVisible}
                closeModal={closeModal}
            />
            {screenWidth > 635 ? <DirectorTable /> : <DirectorCard />}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        screenWidth: state.ScreenResize.screenWidth,
    };
};

const mapDispatchToProps = {
    getDirectors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Directors);
