import { AnyAction } from 'redux';

interface UpdateUser {
    loading: boolean;
}

const initialState: UpdateUser = {
    loading: false,
};
export const UpdateManager = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'LOADING_UPDATE_MANAGER':
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
