import { notification } from 'antd';
import Axios from '../../../axios/axios';

export const updatePassword =
    (password: string, userId: number) => async (dispatch) => {
        dispatch({
            type: 'LOADING_UPDATE_PASS',
            payload: true,
        });
        try {
            await Axios.post('/updatePass', {
                password,
                userId,
            });

            notification.success({
                message: 'Contraseña cambiada.',
                description: '¡La contraseña ha sido cambiada exitosamente!',
            });
        } catch (error) {
            notification.error({
                message: 'Contraseña fallida.',
                description: '¡No se ha podido cambiar la contraseña!',
            });
        } finally {
            dispatch({
                type: 'LOADING_UPDATE_PASS',
                payload: false,
            });
        }
    };
