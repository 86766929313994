import { notification } from 'antd';
import { AxiosError } from 'axios';
import Axios from '../../../axios/axios';

export const createManager =
    (
        email: string,
        name: string,
        lastName: string,
        workPosition: string,
        phone: string,
        franchiseId: number[],
        password: string,
        companyId: number,
    ) =>
    async (dispatch) => {
        dispatch({
            type: 'LOADING_MANAGER',
            payload: true,
        });

        try {
            await Axios.post('/users', {
                email,
                name,
                lastName,
                photo: 'uri',
                passwd: password,
                phone,
                franchiseId,
                workPosition,
                companyId,
            });

            notification.success({
                message: 'Supervisor creado.',
                description: '¡El supervisor ha sido creado exitosamente!',
            });
        } catch (error) {
            // const err = error as AxiosError;
            console.log(error, 'new user');
            notification.error({
                message: 'Supervisor no creado.',
                description: '¡El supervisor no ha sido creado!',
            });
        } finally {
            dispatch({
                type: 'LOADING_MANAGER',
                payload: false,
            });
        }
    };
