import { AppDispatch } from '@store/index';
import { notification } from 'antd';
import Axios from '../../../axios/axios';

export const getManagers = () => async (dispatch: AppDispatch) => {
    dispatch({
        type: 'LOADING_MANAGERS',
        payload: true,
    });
    try {
        let response = await Axios.get(
            `users?listingType=company&userType=manager`,
        );

        if (response.data.message !== 'There are not users to show') {
            response.data = response.data;
        } else {
            response.data = [];
        }

        const activeUsers = response.data?.filter(
            (user) => user.isActive === 1,
        );

        dispatch({
            type: 'GET_MANAGERS',
            payload: activeUsers,
        });
    } catch (error) {
        notification.error({
            message: 'Ocurrió un error.',
            description: '¡No se pudieron cargar los gerentes!',
        });
    } finally {
        dispatch({
            type: 'LOADING_MANAGERS',
            payload: false,
        });
    }
};
