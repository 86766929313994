import React, { useState } from 'react';
import { Typography, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ReactCodeInput from 'react-verification-code-input';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetCodeRecover } from '@store/Actions/GetCodeRecover';
import { RootState } from '@store/index';

interface Props {
    GetCodeRecover: (code: number) => void;
    loading: boolean;
}

const CompareCode: React.FC<Props> = ({
    GetCodeRecover,
    loading,
}): JSX.Element => {
    const { Title, Text } = Typography;
    const history = useHistory();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const handleOnComplete = async (value: string) => {
        const parseValue = parseInt(value);
        await GetCodeRecover(parseValue);
        history.push('/recover-password');
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                // width: '100%',
                height: '100vh',
                padding: '0 1em',
            }}
        >
            <Title level={2}>Codigo de verificación.</Title>
            <Text>
                Por favor ingrese su codigo de verificación de 6 digitos que le
                fue porpocionado a su correo.
            </Text>
            {/* Component here */}
            <div style={{ margin: '1em 1em' }}>
                <ReactCodeInput
                    onComplete={(value: string) => handleOnComplete(value)}
                    fields={6}
                    fieldWidth={50}
                />
            </div>
            {loading && <Spin indicator={antIcon} />}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.RecoverPassword.loading,
    };
};

const mapDispatchToProps = {
    GetCodeRecover,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareCode);
