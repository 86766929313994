import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { RootState } from '@store/index';
import { getAllUsers } from '@store/Actions/GetUsers';
import { deleteUser } from '@store/Actions/DeleteUser';
import CardFilter from '@components/CardFilter';
import UpdateUser from '@components/UpdateUser';
import NoResults from '@assets/icons/no-results.png';

interface IProps {
    getAllUsers: (franchiseId: number) => void;
    deleteUser: (
        userId: number,
        email: string,
        name: string,
        lastName: string,
        startShiftTime: string,
        endShiftTime: string,
        photo: string,
        passwd: string,
        phoneNumber: string,
        franchiseId: number,
        isActive: number,
    ) => void;
    users: any;
    loading: boolean;
    activeUser: [];
    userAuth: {
        companyId: number;
        franchiseId: number;
    };
}

interface IUser {
    email: string;
    endShiftTime: string;
    franchise: string;
    franchiseId: number;
    isActive: number;
    lastName: string;
    name: string;
    password: string;
    phoneNumber: string;
    photo: string;
    startShiftTime: string;
    userId: number;
}

interface IActiveUser {
    email: string;
    endShiftTime: string;
    franchise: string;
    franchiseId: number;
    isActive: number;
    lastName: string;
    name: string;
    nameComplete: string;
    password: string;
    phoneNumber: string;
    photo: string;
    shiftSchedule: string;
    startShiftTime: string;
    userId: number;
}

const UserCard: React.FC<IProps> = ({
    getAllUsers,
    loading,
    deleteUser,
    activeUser,
    userAuth,
}): JSX.Element => {
    const franchiseId = parseInt(Cookies.get('franchiseId') ?? '');
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [data, setData] = useState<any>([]);
    const [rowData, setRowData] = useState<IActiveUser>({
        email: '',
        endShiftTime: '',
        franchise: '',
        franchiseId: 0,
        isActive: 0,
        lastName: '',
        name: '',
        nameComplete: '',
        password: '',
        phoneNumber: '',
        photo: '',
        shiftSchedule: '',
        startShiftTime: '',
        userId: 0,
    });

    useEffect(() => {
        getAllUsers(franchiseId);
    }, []);

    const searchFilter = (search) => {
        setQuery(search);

        const searchResult = activeUser.filter(
            (query: IActiveUser) =>
                query.nameComplete
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                query.email.toLowerCase().includes(search.toLowerCase()) ||
                query.franchise.toLowerCase().includes(search.toLowerCase()) ||
                query.phoneNumber.includes(search),
        );

        if (search.length > 0) {
            setData(searchResult);
        } else {
            setData(activeUser);
        }
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleUpdate = (user: IActiveUser) => {
        setModalVisible(true);
        setRowData(user);
    };

    const handleDelete = async (user: IUser) => {
        await deleteUser(
            user.userId,
            user.email,
            user.name,
            user.lastName,
            user.startShiftTime,
            user.endShiftTime,
            user.photo,
            user.password,
            user.phoneNumber,
            user.franchiseId,
            0,
        );
        getAllUsers(userAuth.franchiseId);
    };

    const menu = (user: IActiveUser) => (
        <Menu onClick={() => {}} key={user.userId}>
            <Menu.Item key="0">
                <a onClick={() => handleUpdate(user)}>Editar</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a onClick={() => handleDelete(user)}>Eliminar</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            {loading ? (
                <>
                    <Card loading={loading} style={{ marginBottom: '1.5em' }} />
                    <Card loading={loading} style={{ marginBottom: '1.5em' }} />
                    <Card loading={loading} />
                </>
            ) : (
                <>
                    <div
                        style={{
                            marginBottom: '1em',
                        }}
                    >
                        <CardFilter
                            query={query}
                            changeQuery={searchFilter}
                            placeholder="Filtrar por nombre, sucursal, telefono y correeo"
                        />
                    </div>
                    {data.length !== 0 ? (
                        data.map((user: IActiveUser) => (
                            <Card
                                style={{ marginBottom: '1.5em' }}
                                key={user.userId}
                                extra={
                                    <Dropdown
                                        overlay={() => menu(user)}
                                        trigger={['click']}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>
                                            <MoreOutlined />
                                        </a>
                                    </Dropdown>
                                }
                            >
                                <Card
                                    type="inner"
                                    title="Nombre"
                                    bordered={false}
                                >
                                    {user.nameComplete}
                                </Card>
                                <Card
                                    type="inner"
                                    title="Correo"
                                    bordered={false}
                                >
                                    {user.email}
                                </Card>
                                <Card
                                    type="inner"
                                    title="Telefono"
                                    bordered={false}
                                >
                                    {user.phoneNumber}
                                </Card>
                                <Card
                                    type="inner"
                                    title="Horario"
                                    bordered={false}
                                >
                                    {user.shiftSchedule}
                                </Card>
                                <Card
                                    type="inner"
                                    title="Locación"
                                    bordered={false}
                                >
                                    {user.franchise}
                                </Card>
                            </Card>
                        ))
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={NoResults}
                                style={{
                                    height: 200,
                                    width: 200,
                                    margin: '0 auto',
                                }}
                            />
                            <span style={{ color: 'grey' }}>
                                ¡Aun no hay colaboradores!
                            </span>
                        </div>
                    )}
                </>
            )}

            <UpdateUser
                modalVisible={modalVisible}
                closeModal={closeModal}
                rowData={rowData}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        users: state.GetUsers.users,
        loading: state.GetUsers.loading,
        activeUser: state.ActiveUsers.activeUsers,
        userAuth: state.IsAuth.userAuth,
    };
};

const mapDispatchToProps = {
    getAllUsers,
    deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
