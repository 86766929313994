import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Typography } from 'antd';
import {
    UserOutlined,
    EyeTwoTone,
    EyeInvisibleOutlined,
    LockOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { authentication } from '@store/Actions/Login';
import Logo from '@assets/icons/Login.png';
import { RootState } from '@store/index';
import './styles.css';

interface Login {
    authentication: (name: string, password: string) => void;
    loading: boolean;
}

const Login: React.FC<Login> = ({ authentication, loading }) => {
    const { Text } = Typography;

    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        authentication(values.username, values.password);
    };

    return (
        <div className="login__container">
            <div className="login__logo">
                <img src={Logo} alt="Chiff" />
            </div>
            <div className="login__form">
                <Form
                    name="basic"
                    onFinish={onFinish}
                    layout="vertical"
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese un usuario!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Usuario"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese una contraseña!',
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder="Contraseña"
                            prefix={<LockOutlined />}
                            iconRender={(visible) =>
                                visible ? (
                                    <EyeTwoTone />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )
                            }
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 9, span: 15 }}>
                        <Button
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                        >
                            Entrar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Link to="/recover-email">
                <Text type="secondary">¿Olvidaste tu contraseña?</Text>
            </Link>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.Login.loading,
    };
};

const mapDispatchToProps = {
    authentication,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
