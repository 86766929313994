import React, { useState, useEffect } from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useDebounce } from 'use-debounce';

interface Props {
    query: string;
    changeQuery: (text: string) => void;
    placeholder: string;
}

const CardFilter: React.FC<Props> = ({
    query,
    changeQuery,
    placeholder,
}): JSX.Element => {
    const [showInput, setShowInput] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [value] = useDebounce(inputValue, 500);

    useEffect(() => {
        changeQuery(inputValue);
    }, [value]);

    const handleClick = (e) => {
        e.preventDefault();
        setShowInput(!showInput);
    };

    return (
        <div>
            <div style={{ marginBottom: '1em' }}>
                <a onClick={(e) => handleClick(e)}>
                    <FilterOutlined /> Filtrar
                </a>
            </div>
            {showInput && (
                <Input
                    placeholder={`${placeholder}`}
                    onChange={(e) => setInputValue(e.target.value)}
                    allowClear
                />
            )}
        </div>
    );
};

export default CardFilter;
