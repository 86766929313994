import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import {
    PieChartOutlined,
    UserOutlined,
    WarningOutlined,
    CalendarOutlined,
    EnvironmentOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { default as UserHeader } from '@components/Header';
import Chiff from '../assets/icons/Chiff-low.png';
import Cookies from 'js-cookie';
import './style.css';

const MainLayout: React.FC = ({ children }) => {
    const isDirector = Cookies.get('isDirector');
    const location = useLocation();
    const { SubMenu } = Menu;

    const { Header, Content, Sider } = Layout;
    const [isSideBarOpen, setSideBar] = useState<boolean>();
    const [userOpen, setUserOpen] = useState<boolean>();

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () =>
            window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    return (
        <Layout
            style={{
                minHeight: '100vh',
                width: '100%',
            }}
        >
            <Header
                className=""
                style={{
                    paddingRight: 25,
                    paddingLeft: 10,
                    position: 'sticky',
                    top: 0,
                    zIndex: 3,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        // flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    {windowWidth < 991 && (
                        <Button
                            type="text"
                            onClick={() => setSideBar(!isSideBarOpen)}
                            size="large"
                            style={{ color: 'white' }}
                        >
                            {React.createElement(
                                isSideBarOpen
                                    ? MenuUnfoldOutlined
                                    : MenuFoldOutlined,
                            )}
                        </Button>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <img
                            src={Chiff}
                            style={{
                                height: 20,
                                margin: 20,
                            }}
                            alt="chiff"
                        />
                        <div>
                            <UserHeader isUserName={windowWidth > 585} />
                        </div>
                    </div>
                </div>
            </Header>
            <Layout>
                <Sider
                    width={200}
                    className="site-layout-background"
                    theme="light"
                    breakpoint="lg"
                    collapsed={isSideBarOpen}
                    // collapsible={isSideBarOpen}
                    onBreakpoint={(broken) => {
                        setSideBar(broken);
                    }}
                    style={
                        windowWidth > 991
                            ? { position: 'relative', zIndex: 1 }
                            : {
                                  position: 'fixed',
                                  zIndex: 2,
                                  height: '100%',
                                  display: isSideBarOpen ? 'none' : 'block',
                              }
                    }
                >
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[location.pathname]}
                        defaultOpenKeys={['/users']}
                        style={{ marginTop: 35 }}
                    >
                        <Menu.Item key="/" icon={<PieChartOutlined />}>
                            Dashboard
                            <Link to="/" />
                        </Menu.Item>
                        <SubMenu
                            key="/users"
                            icon={<UserOutlined />}
                            title="Usuarios"
                        >
                            <Menu.Item key="/employee">
                                Colaborador
                                <Link to="/employee" />
                            </Menu.Item>
                            <Menu.Item key="/manager">
                                Supervisor
                                <Link to="/manager" />
                            </Menu.Item>
                            {isDirector === 'true' && (
                                <Menu.Item key="/director">
                                    Administrador
                                    <Link to="/director" />
                                </Menu.Item>
                            )}
                        </SubMenu>

                        <Menu.Item key="/incidents" icon={<WarningOutlined />}>
                            Incidencias
                            <Link to="/incidents" />
                        </Menu.Item>
                        <Menu.Item key="/tasks" icon={<CalendarOutlined />}>
                            Tareas
                            <Link to="/tasks" />
                        </Menu.Item>
                        <Menu.Item
                            key="/locations"
                            icon={<EnvironmentOutlined />}
                        >
                            Locaciones
                            <Link to="/locations" />
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout style={{ padding: '0' }}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 0,
                            margin: 0,
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
