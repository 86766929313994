import React from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { connect } from 'react-redux';
import { RootState } from '@store/index';
import { createDirector } from '@store/Actions/CreateDirector';
import { getDirectors } from '@store/Actions/GetDirectors';
import Cookies from 'js-cookie';

interface Props {
    modalVisible: boolean;
    closeModal: () => void;
    loading: boolean;
    createDirector: (
        email: string,
        name: string,
        lastName: string,
        workPosition: string,
        phone: string,
        password: string,
        companyId: number,
    ) => void;
    getDirectors: () => void;
}

const CreateDirector: React.FC<Props> = ({
    modalVisible,
    closeModal,
    loading,
    createDirector,
    getDirectors,
}) => {
    const companyId = Cookies.get('companyId');
    const [form] = Form.useForm();

    const onOk = async () => {
        const values = await form.validateFields();
        await createDirector(
            values.email,
            values.name,
            values.lastName,
            'director',
            values.phone,
            values.password,
            parseInt(companyId ?? ''),
        );
        form.resetFields();
        closeModal();
        getDirectors();
    };

    return (
        <Modal
            visible={modalVisible}
            onCancel={() => closeModal()}
            onOk={() => onOk()}
            okText="Crear"
            cancelText="Cancelar"
            confirmLoading={loading}
            title="Nuevo Director"
        >
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
            >
                <Form.Item
                    label="Nombres"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, ingrese un nombre',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    label="Apellidos"
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, ingrese un apellido',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            pattern:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Por favor, ingrese un correo',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    label="Telefono"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            pattern:
                                /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/,
                            message: 'Por favor, ingrese un telefono',
                        },
                    ]}
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                        {
                            min: 8,
                            required: true,
                            message:
                                'Por favor, ingrese una constraseña de 8 caracteres minimo',
                        },
                    ]}
                >
                    <Input type="password" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.CreateDirector.loading,
    };
};

const MapDispatchToProps = {
    createDirector,
    getDirectors,
};

export default connect(mapStateToProps, MapDispatchToProps)(CreateDirector);
