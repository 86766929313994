import { notification } from 'antd';
import Cookies from 'js-cookie';
import { AppDispatch } from '@store/index';
import Axios from '../../../axios/axios';
import { AxiosError } from 'axios';
// import IsAuth from '../../Apis/IsAuth';

export const authentication =
    (user: string, passwd: string) => async (dispatch: AppDispatch) => {
        dispatch({
            type: 'LOADING',
            payload: true,
        });

        try {
            const response = await Axios.post('/login', {
                user,
                passwd,
                from: 'web',
            });

            Cookies.set('token', response.data.token);
            Cookies.set('franchiseId', response.data.franchiseId);
            Cookies.set('companyId', response.data.companyId);
            Cookies.set('isDirector', response.data.isDirector);

            dispatch({
                type: 'USER_LOGGED',
                payload: response.data,
            });
            dispatch({
                type: 'IS_AUTHENTICATED',
                payload: true,
            });
            dispatch({
                type: 'LOADING',
                payload: false,
            });
        } catch (error) {
            dispatch({
                type: 'IS_AUTHENTICATED',
                payload: false,
            });
            dispatch({
                type: 'LOADING',
                payload: false,
            });
            notification.error({
                message: 'Ocurrio un error.',
                description: 'Usuario o contraseña incorrectos',
            });
        }
    };

export const verifyToken = () => (dispatch: AppDispatch) => {
    const token = Cookies.get('token');
    if (token === undefined) return;

    dispatch({
        type: 'IS_AUTHENTICATED',
        payload: true,
    });
};

export const verifyAuth = (token: string) => async (dispatch: AppDispatch) => {
    dispatch({
        type: 'LOADING_USER',
        payload: true,
    });

    try {
        const response = await Axios.post('/auth', {
            token,
        });

        dispatch({
            type: 'USER_AUTH',
            payload: response.data,
        });

        dispatch({
            type: 'LOADING_USER',
            payload: false,
        });
    } catch (error) {
        dispatch({
            type: 'LOADING_USER',
            payload: false,
        });
    }
};
