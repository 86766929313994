import { AnyAction } from 'redux';

interface IAuth {
    userAuth: string[];
    loading: boolean;
}

const initialState: IAuth = {
    userAuth: [],
    loading: false,
};

export const IsAuth = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'USER_AUTH':
            return {
                ...state,
                userAuth: action.payload,
            };
        case 'LOADING_USER':
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
