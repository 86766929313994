import { notification } from 'antd';
import Axios from '../../../axios/axios';

export const GetCodeRecover = (code: number) => async (dispatch) => {
    dispatch({
        type: 'LOADING_UPDATE_PASS',
        payload: true,
    });
    try {
        const response = await Axios.post('/compareCode', {
            code,
        });

        dispatch({
            type: 'GET_CODE_DATA',
            payload: response.data.message[0],
        });
    } catch (error) {
        notification.error({
            message: 'Codigo fallido.',
            description: '¡El codigo de verificación es incorrecto!',
        });
    } finally {
        dispatch({
            type: 'LOADING_UPDATE_PASS',
            payload: false,
        });
    }
};
